import jwt_decode from 'jwt-decode';

declare interface MyToken {
  name: string;
  exp: number;
}

export const CheckToken = (token: string) => {
  try {
    const decodedToken: MyToken = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    return !(decodedToken.exp < currentTime);
  } catch (error) {
    return false;
  }
};
