import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AccountSettingsIcon from 'mdi-react/AccountCogOutlineIcon';
import AccountLockIcon from 'mdi-react/AccountLockOutlineIcon';

import { NavBar } from '../../shared/nav-bar';
import { TextInput } from '../../ui-kit/text-input';
import { useUserAthentication } from '../../../helpers/context/auth';
import { Button } from '../../ui-kit/button';
import {
  emailValidation,
  passwordValidation,
} from '../../../helpers/utils/inputs-validation';
import { DatePickerInput } from '../../ui-kit/date-picker';
import { editUserInfo } from '../../../api/index';

import styles from './account.scss';

const Account = (): React.ReactElement => {
  const {
    user: {
      id: userId,
      email: userEmail,
      firstName: userFirstName,
      lastName: userLastName,
      dateOfBirth: userDateOfBirth,
      establishment: { code, name },
    },
    updateUser,
  } = useUserAthentication();
  const [loadingMail, setMailLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    currentPassowrd: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [page, setPage] = useState(0);
  useEffect(() => {
    setUserData({
      ...userData,
      email: userEmail,
      firstName: userFirstName,
      lastName: userLastName,
      dateOfBirth: userDateOfBirth,
    });
  }, [useUserAthentication().user]);
  const [passwordErrors, setPasswordErrors] = useState<{
    [key: string]: boolean;
  }>({
    currentPassowrd: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({
    email: false,
    firstName: false,
    lastName: false,
    dateOfBirth: false,
    currentPassowrd: false,
  });
  const [edit, setEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };
  const handleBlurInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setErrors({
      ...errors,
      [name]: name === 'email' ? !emailValidation(value) : !value,
    });
  };

  const handleEditUserInfo = () => {
    const isValidate = !Object.keys(errors).some(
      (errorsKeys) => errorsKeys !== 'email' && errors[errorsKeys],
    );
    if (isValidate && userId) {
      setLoading(true);
      editUserInfo(
        {
          firstName: userData.firstName,
          lastName: userData.lastName,
          dateOfBirth: userData.dateOfBirth,
        },
        userId,
      )
        .then((res: any) => {
          setLoading(false);
          setEdit(false);
          updateUser({ ...res });
          enqueueSnackbar('mise à jour avec succés', {
            variant: 'success',
            autoHideDuration: 2000,
          });
        })
        .catch((err) => {
          setLoading(false);
          setEdit(false);
          enqueueSnackbar(err.message || 'un erreur provenant du serveur', {
            variant: 'error',
            autoHideDuration: 2000,
          });
        });
    }
  };
  const handleSubmitNewPassword = () => {
    const isValidate = !Object.keys(passwordErrors).some(
      (errorsKeys) => passwordErrors[errorsKeys],
    );
    if (
      userData.currentPassowrd &&
      userData.newPassword &&
      userData.confirmPassword &&
      userData.currentPassowrd !== userData.newPassword &&
      userData.newPassword === userData.confirmPassword
    ) {
      if (isValidate && userId) {
        setLoading(true);
        editUserInfo(
          {
            email: userData.email,
            oldPassword: userData.currentPassowrd,
            password: userData.newPassword,
          },
          userId,
        )
          .then((res: any) => {
            setLoading(false);
            setEdit(false);
            setUserData({
              ...userData,
              currentPassowrd: '',
              newPassword: '',
              confirmPassword: '',
            });
            enqueueSnackbar('mise à jour avec succés', {
              variant: 'success',
              autoHideDuration: 2000,
            });
          })
          .catch((err) => {
            setLoading(false);
            setEdit(false);
            setUserData({
              ...userData,
              currentPassowrd: '',
              newPassword: '',
              confirmPassword: '',
            });
            enqueueSnackbar(err.message || 'un erreur provenant du serveur', {
              variant: 'error',
              autoHideDuration: 2000,
            });
          });
      }
    } else {
      setPasswordErrors({
        ...passwordErrors,
        currentPassowrd: !passwordValidation(userData.currentPassowrd),
        newPassword:
          !passwordValidation(userData.newPassword) ||
          userData.currentPassowrd === userData.newPassword,
        confirmPassword:
          !passwordValidation(userData.confirmPassword) ||
          userData.confirmPassword !== userData.newPassword,
      });
    }
  };
  const handleChangeEmail = () => {
    if (emailValidation(userData.email) && userId) {
      setMailLoading(true);
      editUserInfo(
        {
          email: userData.email,
        },
        userId,
      )
        .then((res: any) => {
          setMailLoading(false);
          setEdit(false);
          setUserData({
            ...res,
          });
          updateUser({ ...res });
          enqueueSnackbar(`L'adresse e-mail a été bien enregistré`, {
            variant: 'success',
            autoHideDuration: 2000,
          });
        })
        .catch((err) => {
          setMailLoading(false);
          setEdit(false);
          setUserData({
            ...userData,
            email: userEmail,
          });
          enqueueSnackbar(err.message || 'un erreur provenant du serveur', {
            variant: 'error',
            autoHideDuration: 2000,
          });
        });
    }
  };
  return (
    <div className={styles.accountContainer}>
      <NavBar />
      <div className={styles.content}>
        <Tabs>
          <TabList className={styles.tabsList}>
            <Tab
              className={`${page === 0 && styles.active}`}
              onClick={() => {
                setUserData({
                  ...userData,
                  currentPassowrd: '',
                  newPassword: '',
                  confirmPassword: '',
                  email: userEmail,
                });
                setPasswordErrors({
                  currentPassowrd: false,
                  newPassword: false,
                  confirmPassword: false,
                });
                setErrors({
                  ...errors,
                  email: false,
                });
                setPage(0);
                setEdit(false);
              }}
            >
              <AccountSettingsIcon color={'#706f6f'} />
            </Tab>
            <Tab
              className={`${page === 1 && styles.active}`}
              onClick={() => {
                setPage(1);
                setUserData({
                  ...userData,
                  firstName: userFirstName,
                  lastName: userLastName,
                  dateOfBirth: userDateOfBirth,
                });
                setErrors({
                  firstName: false,
                  lastName: false,
                  dateOfBirth: false,
                });
                setEdit(false);
              }}
            >
              <AccountLockIcon color={'#706f6f'} />
            </Tab>
          </TabList>
          <TabPanel>
            <span className={styles.accountTitle}>
              Paramètres généraux du compte
            </span>
            <div className={styles.accountTable}>
              <div className={styles.accountRow}>
                <h3>Nom</h3>
                {edit ? (
                  <TextInput
                    className={styles.inputAccount}
                    value={userData.lastName}
                    placeholder={'Saisi votre Nom'}
                    type={'text'}
                    name={'lastName'}
                    error={errors.lastName}
                    errorText={'entrez votre Nom'}
                    secondary={true}
                    onChange={handleChangeInput}
                    onBlur={handleBlurInput}
                  />
                ) : (
                  <span>{userData.lastName}</span>
                )}
              </div>
              <div className={styles.accountRow}>
                <h3>Prénom</h3>
                {edit ? (
                  <TextInput
                    className={styles.inputAccount}
                    value={userData.firstName}
                    placeholder={'Saisi votre Prénom'}
                    type={'text'}
                    name={'firstName'}
                    error={errors.firstName}
                    errorText={'entrez votre Prénom'}
                    secondary={true}
                    onChange={handleChangeInput}
                    onBlur={handleBlurInput}
                  />
                ) : (
                  <span>{userData.firstName}</span>
                )}
              </div>
              <div className={styles.accountRow}>
                <h3>Date de naissance</h3>
                {/* {edit ? (
                  <DatePickerInput
                    className={`${styles.datePickerAccount} ${
                      errors.dateOfBirth && styles.datePickerAccountError
                    }`}
                    value={new Date(userData.dateOfBirth)}
                    placeholderText={'Saisi votre Date de naissance'}
                    onBlur={() => setErrors({ ...errors, dateOfBirth: false })}
                    error={errors.dateOfBirth}
                    errorText={'entrez votre Date de naissance'}
                    onChange={(item) => {
                      setErrors({ ...errors, dateOfBirth: !item });
                      setUserData({
                        ...userData,
                        dateOfBirth: item
                          ? moment(item).format('YYYY-MM-DD')
                          : moment().format('YYYY-MM-DD'),
                      });
                    }}
                    maxDate={new Date()}
                    minDate={new Date(2000, 1, 1)}
                  />
                ) : (
                  <span>
                    {moment(userData.dateOfBirth, 'YYYY-MM-DD').format(
                      'DD/MM/YYYY',
                    )}
                  </span>
                )} */}
                <span>
                  {moment(userData.dateOfBirth, 'YYYY-MM-DD').format(
                    'DD/MM/YYYY',
                  )}
                </span>
              </div>
              <div className={styles.accountRow}>
                <h3>Nom de l'etablissement</h3>
                <span>{name}</span>
              </div>
              <div className={styles.accountRow}>
                <h3>Code d'etablissement</h3>
                <span>{code}</span>
              </div>
            </div>
            <div className={styles.actionsContainer}>
              {edit && (
                <Button
                  className={styles.editButton}
                  value={'annuler'}
                  secondary={true}
                  outlined={true}
                  onClick={() => {
                    setErrors({
                      ...errors,
                      email: false,
                      firstName: false,
                      lastName: false,
                      dateOfBirth: false,
                    });
                    setUserData({
                      ...userData,
                      email: userEmail,
                      firstName: userFirstName,
                      lastName: userLastName,
                      dateOfBirth: userDateOfBirth,
                    });
                    setEdit(false);
                  }}
                />
              )}
              {edit && (
                <Button
                  className={styles.editButton}
                  value={'enregistrer'}
                  warning={true}
                  onClick={handleEditUserInfo}
                  loading={isLoading}
                />
              )}
              {!edit && (
                <Button
                  className={styles.editButton}
                  value={'modifier'}
                  warning={true}
                  onClick={() => setEdit(true)}
                  loading={isLoading}
                />
              )}
            </div>
          </TabPanel>
          <TabPanel>
            <span className={styles.accountTitle}>
              Changer l'adresse e-mail
            </span>
            <div className={styles.accountTable}>
              <div className={styles.accountRow}>
                <h3>email</h3>
                {edit ? (
                  <TextInput
                    className={styles.inputAccount}
                    value={userData.email}
                    placeholder={'Saisi votre adresse e-mail'}
                    type={'text'}
                    name={'email'}
                    error={errors.email}
                    errorText={'entrez votre adresse e-mail'}
                    secondary={true}
                    onChange={handleChangeInput}
                    onBlur={handleBlurInput}
                  />
                ) : (
                  <span>{userData.email}</span>
                )}
              </div>
            </div>
            <div className={styles.actionsContainer}>
              {edit && (
                <Button
                  value={'Annuler'}
                  outlined={true}
                  className={styles.editButton}
                  onClick={() => {
                    setUserData({
                      ...userData,
                      email: userEmail,
                    });
                    setErrors({
                      ...errors,
                      email: false,
                    });
                    setEdit(false);
                  }}
                />
              )}
              <Button
                value={'Modifier'}
                className={styles.editButton}
                onClick={
                  !edit ? () => setEdit(true) : () => handleChangeEmail()
                }
                loading={loadingMail}
              />
            </div>
            <span className={styles.accountTitle}>Changer le mot de passe</span>
            <div className={styles.accountTable}>
              <div className={styles.accountRow}>
                <h3>Actuel</h3>
                <TextInput
                  className={styles.inputAccount}
                  value={userData.currentPassowrd}
                  placeholder={'Saisi votre mot de passe Actuelle'}
                  type={'password'}
                  name={'currentPassowrd'}
                  error={passwordErrors.currentPassowrd}
                  errorText={
                    !userData.currentPassowrd
                      ? 'entrez votre mot de passe actuel'
                      : 'mot de passe non valide (8 caractères min, avec MAJ et minuscules, un chiffre, un caractère spécial)'
                  }
                  secondary={true}
                  onChange={handleChangeInput}
                  onBlur={(e) => {
                    setPasswordErrors({
                      ...passwordErrors,
                      currentPassowrd: !passwordValidation(e.target.value),
                      newPassword:
                        !passwordValidation(userData.currentPassowrd) ||
                        userData.newPassword === e.target.value,
                    });
                  }}
                />
              </div>
              <div className={styles.accountRow}>
                <h3>Nouveau</h3>
                <TextInput
                  className={styles.inputAccount}
                  value={userData.newPassword}
                  placeholder={'Saisi un nouveau mot de passe'}
                  type={'password'}
                  name={'newPassword'}
                  error={passwordErrors.newPassword}
                  errorText={
                    !userData.newPassword
                      ? 'entrez votre nouveau mot de passe'
                      : userData.newPassword === userData.currentPassowrd
                      ? `votre mot de passe actuel et le nouveau ne doivent pas ètre identiques`
                      : 'mot de passe non valide (8 caractères min, avec MAJ et minuscules, un chiffre, un caractère spécial)'
                  }
                  secondary={true}
                  onChange={handleChangeInput}
                  onBlur={(e) =>
                    setPasswordErrors({
                      ...passwordErrors,
                      newPassword:
                        !passwordValidation(e.target.value) ||
                        userData.currentPassowrd === userData.newPassword,
                    })
                  }
                />
              </div>
              <div className={styles.accountRow}>
                <h3>Confirmer</h3>
                <TextInput
                  className={styles.inputAccount}
                  value={userData.confirmPassword}
                  placeholder={'confirmez votre mot de passe'}
                  type={'password'}
                  name={'confirmPassword'}
                  error={passwordErrors.confirmPassword}
                  errorText={
                    !userData.confirmPassword
                      ? 'confirmez votre nouveau mot de passe'
                      : 'assurez-vous que vos mots de passe correspondent'
                  }
                  secondary={true}
                  onChange={handleChangeInput}
                  onBlur={(e) =>
                    setPasswordErrors({
                      ...passwordErrors,
                      confirmPassword:
                        !userData.newPassword ||
                        e.target.value !== userData.newPassword,
                    })
                  }
                />
              </div>
            </div>
            <div className={styles.actionsContainer}>
              <Button
                value={'Enregistrer'}
                className={styles.editButton}
                onClick={handleSubmitNewPassword}
                loading={isLoading}
              />
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Account;
