import React from 'react';
import Linkify from 'react-linkify';

import SpeachIcon from '../../../images/avatar-Speach.svg';
import styles from './discussions-components.scss';

declare interface ISpeachMessage {
  text: string | string[];
}
export const SpeachMessage = ({
  text,
}: ISpeachMessage): React.ReactElement<ISpeachMessage> => {
  const uploadMessage = (message: string) =>
    message.includes('api/download') || message.startsWith("http") && message.endsWith(".gif") ? (
      <img className={styles.messageImg} src={message} alt="uploaded img" />
    ) : (
      <Linkify
        componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="_blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}
      >
        <p className={styles.speachMessageText}>{message}</p>
      </Linkify>
    );
  return (
    <div className={styles.speachMessageContainer}>
      {Array.isArray(text) ? (
        text.map((message, ind) => {
          return (
            <div className={styles.speachMessageItem} key={ind}>
              {ind === text.length - 1 && (
                <img
                  className={styles.speachMessageImg}
                  src={SpeachIcon}
                  alt='*'
                />
              )}
              {uploadMessage(message)}
            </div>
          );
        })
      ) : (
        <div className={styles.speachMessageItem}>
          <img className={styles.speachMessageImg} src={SpeachIcon} alt='*' />
          {uploadMessage(text)}
        </div>
      )}
    </div>
  );
};
