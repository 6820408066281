export const initialUser = {
  confirmationToken: '',
  createdAt: '',
  dateOfBirth: '',
  email: '',
  establishment: {
    code: '',
    createdAt: '',
    email: '',
    id: 0,
    isArchived: false,
    name: '',
    postalCode: '',
    type: '',
    updatedAt: new Date(),
    userFirstName: '',
    userGender: '',
    userLastName: '',
    firstName: '',
    hasConfirmedAccount: true,
  },
  id: null,
  invitationToken: null,
  isArchived: false,
  lastName: '',
  role: null,
  updatedAt: new Date(),
  userSettings: { hasActiveNotification: false, id: 0 },
};
