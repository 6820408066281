import React, { useEffect } from 'react';
import Stories from 'react-insta-stories';

import SpeachIcon from '../../../images/avatar-Speach.svg';
import CloseIcon from '../../../images/Icon-Close-Filled.svg';
import { getDateDiffByNow } from '../../../helpers/utils/get-date-diff-by-now';
import { incrementStoryVueNumber } from '../../../api';

import styles from './speach-stories.scss';

declare interface ISpeachStories {
  onClick?: () => void;
  arrayOfStories: any[];
}
export const SpeachStories = ({
  onClick,
  arrayOfStories,
}: ISpeachStories): React.ReactElement<ISpeachStories> => {
  const addVueToStory = (storyId: number, story: any) => {
    const storyObj = {
      ...story,
      isSeen: true,
    };
    incrementStoryVueNumber(storyId, storyObj)
      .then()
      .catch((error) => console.error(error.message));
  };
  useEffect(() => {
    let i = 0;
    addVueToStory(arrayOfStories[i].id, arrayOfStories[i]);
    if (arrayOfStories.length > 1) {
      const Timer = setInterval(() => {
        i = i + 1;
        addVueToStory(arrayOfStories[i].id, arrayOfStories[i]);
        if (i === arrayOfStories.length - 1) {
          clearInterval(Timer);
        }
      }, 4000);
    }
  }, []);

  const stories = arrayOfStories.map((story, ind) => {
    return {
      content: () => (
        <div
          style={{
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            height: '100%',
            width: '100%',
            boxSizing: 'border-box',
            backgroundImage: `url('${story.mediaName}')`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
          }}
          key={ind}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: '12px',
              marginTop: '1.5rem',
              width: '100%',
            }}
          >
            <img
              src={SpeachIcon}
              alt={'*'}
              style={{ width: '36px', height: '36px' }}
            />
            <label
              style={{
                margin: '0 0.6rem',
                fontWeight: 'bolder',
                color: ' #343434',
              }}
            >
              Speach
            </label>
            <label
              style={{
                letterSpacing: '0.38px',
                color: '#343434',
                fontSize: '0.6rem',
                marginTop: '0.2rem',
              }}
            >
              {getDateDiffByNow(story.createdAt)}
            </label>
          </div>
          {story.attachedUrl && (
            <a
              href={story.attachedUrl}
              target={'_blank'}
              style={{
                position: 'absolute',
                marginTop: '35rem',
                backgroundColor: 'rgba(255,255,255,0.5)',
                opacity: '0.5',
                cursor: 'pointer',
                color: 'black',
                border: '2px solid black',
                padding: '8px 24px',
                borderRadius: '8px',
                width: '250px',
                zIndex: 9999999999999999999,
                textAlign: 'center',
                textDecoration: 'none',
                fontSize: '1.2em',
              }}
            >
              voir plus
            </a>
          )}
        </div>
      ),
    };
  });

  return (
    <div>
      <div
        onClick={onClick}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '50%',
          cursor: 'pointer',
          height: '36px',
          width: '36px',
          position: 'absolute',
          zIndex: 9999999999,
          top: '1.2rem',
          right: '.2rem',
        }}
      >
        <img src={CloseIcon} alt={'*'} />
      </div>
      <Stories stories={stories} />
    </div>
  );
};
