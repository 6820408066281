import React from 'react';

import QuestionIcon from '../../../../images/question-icon.jpg';
import { QuestionsBox } from './question-box';

import styles from './question-box.scss';

export const QuestionsBoxContainer = () => {
  return (
    <div className={styles.questionsBox}>
      <div className={styles.imgContainer}>
        <img src={QuestionIcon} alt="" />
      </div>
      <label className={styles.questionsTitle}>La story à questions !</label>
      <p>
        Ta question est anonymisée, l’équipe Speachbot y répond au plus vite en
        story !
      </p>
      <QuestionsBox />
    </div>
  );
};
