import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { ProtectedRoute } from './protected-route';
import { ROUTES } from '../../helpers/consts/routes.constants';

const SignIn = lazy(() => import('../pages/authentication/sign-in'));
const SignUp = lazy(() => import('../pages/authentication/sign-up'));
const PasswordRecovery = lazy(
  () => import('../pages/authentication/forgot-password/password-recovery'),
);
const ResetPassword = lazy(
  () => import('../pages/authentication/forgot-password/reset-password'),
);
const HomePage = lazy(() => import('../pages/home'));
const Settings = lazy(() => import('../pages/settings'));
const Discussions = lazy(() => import('../pages/discussions'));
const TermOfService = lazy(() => import('../pages/terms-of-service'));
const Account = lazy(() => import('../pages/account'));
const ConfirmAccount = lazy(
  () => import('../pages/authentication/confirm-account'),
);

declare interface IRoutesExhibitor {
  isAuthenticated: boolean;
}
export const RoutesExhibitor = ({
  isAuthenticated,
}: IRoutesExhibitor): React.ReactElement<IRoutesExhibitor> => (
  <Switch>
    <Route exact={true} path="/">
      <Redirect to={ROUTES.SIGN_IN} />
    </Route>
    <ProtectedRoute
      component={SignIn}
      path={ROUTES.SIGN_IN}
      redirectPath={ROUTES.HOME}
      withAuth={!isAuthenticated}
    />
    <ProtectedRoute
      component={SignUp}
      path={ROUTES.SIGN_UP}
      redirectPath={ROUTES.HOME}
      withAuth={!isAuthenticated}
    />
    <ProtectedRoute
      component={PasswordRecovery}
      path={ROUTES.PASSWORD_RECOVERY}
      redirectPath={ROUTES.HOME}
      withAuth={!isAuthenticated}
    />
    <ProtectedRoute
      component={ResetPassword}
      path={ROUTES.RESET_PASSWORD}
      redirectPath={ROUTES.HOME}
      withAuth={!isAuthenticated}
    />
    <Route
      exact={true}
      path={ROUTES.CONFIRM_ACCOUNT}
      component={ConfirmAccount}
    />
    <ProtectedRoute
      component={HomePage}
      path={ROUTES.HOME}
      redirectPath={ROUTES.SIGN_IN}
      withAuth={isAuthenticated}
    />
    <ProtectedRoute
      component={Settings}
      path={ROUTES.SETTINGS}
      redirectPath={ROUTES.SIGN_IN}
      withAuth={isAuthenticated}
    />
    <ProtectedRoute
      component={Discussions}
      path={ROUTES.DISCUSSION}
      redirectPath={ROUTES.SIGN_IN}
      withAuth={isAuthenticated}
    />
    <ProtectedRoute
      component={Account}
      path={ROUTES.ACCOUNT}
      redirectPath={ROUTES.SIGN_IN}
      withAuth={isAuthenticated}
    />
    <Route path={ROUTES.TERM_OF_SERVICES} component={TermOfService} />
  </Switch>
);
