import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import WarningIcon from 'mdi-react/WarningIcon';

import { TextInput } from '../../../ui-kit/text-input';
import { AuthWrapper } from '../auth-wrapper';
import Peach from '../../../../images/peach.svg';
import LoginBackground from '../../../../images/login-bg-img.svg';
import { Button } from '../../../ui-kit/button';
import {
  emailValidation,
  passwordValidation,
} from '../../../../helpers/utils/inputs-validation';
import { signIn } from '../../../../api/index';
import { useUserAthentication } from '../../../../helpers/context/auth';
import { Dialog } from '../../../ui-kit/dialog';

import styles from './sign-in.scss';

export const SignIn = (): React.ReactElement => {
  const { logIn } = useUserAthentication();
  const history = useHistory();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
  });
  const [apiError, setApiError] = useState('');
  const [open, toggleOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleBlurInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setErrors({
        ...errors,
        email: !emailValidation(value),
      });
    } else {
      setErrors({
        ...errors,
        password: !passwordValidation(value),
      });
    }
  };
  const handleSignIn = () => {
    const { email, password } = formData;

    if (!email || !password) {
      setErrors({
        ...errors,
        email: !emailValidation(email),
        password: !passwordValidation(password),
      });
    } else {
      if (!errors.email && !errors.password) {
        setLoading(true);
        signIn(formData.email, formData.password)
          .then((res: any) => {
            setLoading(false);
            logIn(res.token, res.user);
          })
          .catch((error) => {
            toggleOpen(true);
            setLoading(false);
            setApiError(
              error.statusCode
                ? error.message
                : 'un erreur se produite provenant du serveur',
            );
          });
      }
    }
  };
  return (
    <AuthWrapper>
      <Dialog
        open={open}
        animation={'scaleUp'}
        onClose={() => toggleOpen(false)}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              color: '#F5A623',
              backgroundColor: 'antiquewhite',
              borderRadius: '8px',
              padding: '16px',
            }}
          >
            <WarningIcon color={'#F5A623'} />
            <label>Attention !!</label>
          </div>
          <div
            style={{
              height: '38vh',
              backgroundColor: 'rgb(242, 243, 245)',
              marginTop: '-8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '16px',
              }}
            >
              <b style={{ color: 'coral' }}>Impossible à se connecter</b>
              <br />
              <p style={{ padding: '.25rem 0 .75rem', fontSize: '0.8em' }}>
                {apiError}
              </p>
            </div>
            <div style={{ margin: 'auto', width: '40%' }}>
              <Button
                value={'OK'}
                warning={true}
                onClick={() => toggleOpen(false)}
                loading={isLoading}
              />
            </div>
          </div>
        </div>
      </Dialog>
      <form className={styles.signInFormContainer}>
        <div className={styles.titleContainer}>
          <label className={styles.title}>Hello Speach</label>
          <img src={Peach} alt={'peach'} />
        </div>
        <TextInput
          value={formData.email}
          placeholder={'email'}
          type={'text'}
          name={'email'}
          onChange={handleChangeInput}
          onBlur={handleBlurInput}
          error={errors.email}
          errorText={
            !formData.email ? `entrez votre adresse mail` : `email invalide`
          }
        />
        <TextInput
          value={formData.password}
          placeholder={'password'}
          type={'password'}
          name={'password'}
          onChange={handleChangeInput}
          onBlur={handleBlurInput}
          error={errors.password}
          errorText={
            !formData.password
              ? `entrez votre mot de passe`
              : `mot de passe non valide (8 caractères min, avec MAJ et minuscules, un chiffre, un caractère spécial`
          }
        />
        <label
          className={`${styles.text} ${styles.forgotPasswordLink}`}
          onClick={() => history.push('/password-recovery')}
        >
          {'>'} Mot de passe oublié
        </label>
        <Button value={'ENTRER'} light={true} onClick={handleSignIn} />
        <label
          className={`${styles.text} ${styles.signUpLink}`}
          onClick={() => history.push('/sign-up')}
        >
          Créer un compte
        </label>
      </form>
      <img src={LoginBackground} alt={'*'} />
    </AuthWrapper>
  );
};
