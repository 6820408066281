import React from 'react';

import styles from './auth.scss';

declare interface IAuthWrapper {
  children: React.ReactNode;
  imgUrl?: string;
}
export const AuthWrapper = ({
  children,
  imgUrl,
}: IAuthWrapper): React.ReactElement<IAuthWrapper> => {
  return (
    <div className={styles.authWrapper}>
      <div
        className={styles.formWrap}
        style={{
          backgroundImage: `url(${imgUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center bottom',
          backgroundSize:'90%'
        }}
      >
        {children}
      </div>
    </div>
  );
};
