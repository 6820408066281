import { BrowserRouter as Router } from 'react-router-dom';
import { Integrations } from '@sentry/tracing';
import { SnackbarProvider } from 'notistack';
import React, { Suspense } from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';

import { useSpeachPosts } from './helpers/costum-hooks/use-speach-posts';
import { RoutesExhibitor } from './components/routes/routes-exhibitor';
import { SpeachPostsContext } from './helpers/context/speach-posts';
import { useUserAuth } from './helpers/costum-hooks/use-User-auth';
import { UserAthentication } from './helpers/context/auth';
import { Environments } from './helpers/consts/envs';

import './index.scss';

export const App = () => {
  const { logIn, logOut, isAuthenticated, user, updateUser } = useUserAuth();
  const {
    updatePostAsCompleted,
    loadMorePosts,
    isFirstLoad,
    currentPage,
    totalPages,
    loadPosts,
    posts,
  } = useSpeachPosts();

  return (
    <UserAthentication.Provider
      value={{ logIn, logOut, isAuthenticated, user, updateUser }}
    >
      <SpeachPostsContext.Provider
        value={{
          isFirstLoad,
          loadPosts,
          loadMorePosts,
          updatePostAsCompleted,
          posts,
          currentPage,
          totalPages,
        }}
      >
        <SnackbarProvider>
          <Router>
            <Suspense fallback={<div>...loading</div>}>
              <RoutesExhibitor isAuthenticated={isAuthenticated} />
            </Suspense>
          </Router>
        </SnackbarProvider>
      </SpeachPostsContext.Provider>
    </UserAthentication.Provider>
  );
};

if (process.env.NODE_ENV !== Environments.Development) {
  Sentry.init({
    dsn: 'https://35c4f1c47fb74b2e8ec5dd9e7bddcdd5@sentry.bptech.dev/13',
    integrations: [new Integrations.BrowserTracing()],
    environment: process.env.NODE_ENV,
    autoSessionTracking: true,
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(<App />, document.getElementById('output'));
