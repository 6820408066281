export enum ROUTES {
  HOME = '/home',
  SIGN_IN = '/sign-in',
  SIGN_UP = '/sign-up',
  PASSWORD_RECOVERY = '/password-recovery',
  RESET_PASSWORD = '/reset-password',
  CONFIRM_ACCOUNT = '/confirm-account',
  SETTINGS = '/settings',
  DISCUSSION = '/discussion/:postId',
  ACCOUNT = '/account',
  TERM_OF_SERVICES = '/term-of-services',
}
