import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import EyeOffOutline from 'mdi-react/EyeOffOutlineIcon';
import AlertCircleIcon from 'mdi-react/AlertCircleOutlineIcon';

import { useOnClickOutside } from '../../../helpers/costum-hooks/use-click-outside';

import styles from './text-input.scss';

declare interface TextInputProps {
  secondary?: boolean;
  light?: boolean;
  value?: string;
  type?: string;
  placeholder?: string;
  name?: string;
  error?: boolean;
  errorText?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
}
export const TextInput = ({
  light = true,
  secondary = false,
  value = '',
  type = 'text',
  placeholder = '',
  name = '',
  onChange,
  onBlur,
  error = false,
  errorText = '',
  className = '',
  ...rest
}: TextInputProps): React.ReactElement<TextInputProps> => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [passwordDisplay, toggleDisplay] = useState(false);
  const handlePasswordDisplay = () => toggleDisplay(!passwordDisplay);
  const props = {
    className: classnames(styles.inputContainer, className, {
      [styles.inputSecondary]: secondary,
      [styles.inputLight]: light,
      [styles.inputError]: error,
    }),
    ...rest,
  };
  const myRef = useRef(null);
  useOnClickOutside(myRef, () => setErrorMessage(false));
  return (
    <div {...props}>
      <input
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={!passwordDisplay ? type : 'text'}
        placeholder={placeholder}
        id={name}
        name={name}
        autoComplete="off"
        {...rest}
      />
      {type === 'password' &&
        (!passwordDisplay ? (
          <EyeOutlineIcon
            id="eyeIcon"
            onClick={handlePasswordDisplay}
            style={{
              cursor: 'pointer',
              color: secondary ? '#767676' : '#ffffff',
            }}
          />
        ) : (
          <EyeOffOutline
            id="eyeIcon"
            onClick={handlePasswordDisplay}
            style={{
              cursor: 'pointer',
              color: secondary ? '#767676' : '#ffffff',
            }}
          />
        ))}
      {error && (
        <div className={styles.errorContainer} ref={myRef}>
          <i
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AlertCircleIcon
              id="eyeIcon"
              style={{
                marginLeft: error ? '4px' : '',
                cursor: 'pointer',
                color: 'red',
              }}
              size={24}
              onClick={(e) => {
                // e.stopPropagation();
                e.preventDefault();
                setErrorMessage(!errorMessage);
              }}
            />
          </i>
          {errorMessage && <span>{errorText}</span>}
        </div>
      )}
    </div>
  );
};
