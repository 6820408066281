import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import LoginBackground from '../../../../images/login-bg-img.svg';
import { TextInput } from '../../../ui-kit/text-input';
import Peach from '../../../../images/peach.svg';
import { recoverPassword } from '../../../../api/index';
import {
  emailValidation,
  passwordValidation,
} from '../../../../helpers/utils/inputs-validation';
import { AuthWrapper } from '../auth-wrapper';
import getUrlParam from '../../../../helpers/utils/get-url-param';
import { useUserAthentication } from '../../../../helpers/context/auth';
import { Button } from '../../../ui-kit/button';

import styles from './forgot-password.scss';

const ResetPassword = (): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirm: '',
  });
  const [errors, setErrors] = useState({
    email: false,
    password: false,
    confirm: false,
  });
  const { logIn } = useUserAthentication();
  const [isLoading, setLoading] = useState(false);

  const UpdatePassword = () => {
    const { email, confirm, password } = formData;
    if (!email || !confirm || !password) {
      setErrors({
        ...errors,
        email: !emailValidation(email),
        password: !passwordValidation(password),
        confirm: !confirm || confirm !== password,
      });
    } else {
      if (!errors.email && !errors.confirm && !errors.password) {
        setLoading(true);
        recoverPassword({
          email: formData.email,
          passwordConfirmationToken: getUrlParam('token'),
          password: formData.password,
        })
          .then((res: any) => {
            setLoading(false);
            enqueueSnackbar('votre mot de passe à été modifié', {
              variant: 'success',
              autoHideDuration: 2000,
            });
            logIn(res.token, res.user);
          })
          .catch((error) => {
            setLoading(false);
            enqueueSnackbar(
              error.message ||
                'un erreur se produite, verifier votre adresse mail',
              {
                variant: 'error',
                autoHideDuration: 2000,
              },
            );
          });
      }
    }
  };
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleBlurInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setErrors({
        ...errors,
        email: !emailValidation(value),
      });
    }

    if (name === 'password') {
      setErrors({
        ...errors,
        password: !passwordValidation(value),
      });
    }

    if (name === 'confirm') {
      setErrors({
        ...errors,
        confirm: value !== formData.password,
      });
    }
  };

  return (
    <AuthWrapper>
      <form className={styles.signInFormContainer}>
        <div className={styles.titleContainer}>
          <label className={styles.title}>Hello Speach</label>
          <img src={Peach} alt={'peach'} />
        </div>
        <TextInput
          value={formData.email}
          placeholder={'entrez votre adresse mail'}
          type={'text'}
          name={'email'}
          onChange={handleChangeInput}
          onBlur={handleBlurInput}
          error={errors.email}
          errorText={
            !formData.email ? 'entrez votre addresse mail' : 'email invalide'
          }
        />
        <TextInput
          value={formData.password}
          placeholder={'entrez le nouveau mot de passe'}
          type={'password'}
          name={'password'}
          onChange={handleChangeInput}
          onBlur={handleBlurInput}
          error={errors.password}
          errorText={
            formData.password
              ? 'mot de passe non valide (8 caractères min, avec MAJ et minuscules, un chiffre, un caractère spécial)'
              : 'entrez votre nouvelle mot de passe'
          }
        />
        <TextInput
          value={formData.confirm}
          placeholder={'confirmer le nouveau mot de passe'}
          type={'password'}
          name={'confirm'}
          onChange={handleChangeInput}
          onBlur={handleBlurInput}
          error={errors.confirm}
          errorText={
            formData.confirm
              ? 'assurez-vous que vos mots de passe correspondent'
              : 'confirmez ton mot de passe'
          }
        />
        <Button value={'VALIDER'} light={true} onClick={UpdatePassword} />
        <div className={styles.returnLinkContainer}>
          <label
            className={`${styles.returnLink}`}
            onClick={() => {
              history.push('/sign-in');
            }}
          >
            Retour à la page login
          </label>
        </div>
      </form>
      <img src={LoginBackground} alt={'*'} />
    </AuthWrapper>
  );
};
export default ResetPassword;
