import React from 'react';
import classnames from 'classnames';

import { SizeProps } from '../../../helpers/lib/interfaces';

import styles from './avatar.scss';

export declare interface AvatarProps extends SizeProps {
  name?: string;
  src?: string;
  disabled?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const Avatar = ({
  // Visual states.
  disabled = false,

  // Sizes.
  small = false,
  big = false,

  name = '',
  src = '',

  className = '',
  children,
  ...rest
}: AvatarProps): React.ReactElement => {
  const initials = getInitialsFromName(name, 2, 'Avatar');

  const props = {
    disabled,
    title: name || undefined,
    className: classnames(
      styles.avatar,
      // `${styles.avatar}-${hashString(name)}`,
      className,
      {
        //[styles.avatarSmall]: small,
        //[styles.avatarBig]: big,
      },
      {
        [styles.avatarDisabled]: disabled,
      },
    ),
    ...rest,
  };
  return !src ? (
    <div /*aria-role={'img'}*/ {...props}>{children || initials}</div>
  ) : (
    <img src={src} {...props} />
  );
};

export const getInitialsFromName = (
  name: string,
  size: number,
  defaultChar: string = '?',
): string => {
  if (name === null || name.trim() === '') {
    const times = new Array(size).fill(null);
    return times
      .map(() => defaultChar)
      .join('')
      .substring(0, size);
  }

  const words = name.split(' ');
  if (words.length <= 1) {
    return words[0].substring(0, size);
  }

  return words
    .map((word) => word.substring(0, 1))
    .join('')
    .substring(0, size);
};
// export const hashString = (str: string) => {
//   if (str) {
//     const stringArr = str.split('');
//     let average: number = stringArr
//       .map((_, index) => str.charCodeAt(index))
//       .reduce((acc, cur) => acc + cur);
//     return (
//       Math.floor((average / Math.pow(10, average.toString().length)) * 8) + 1
//     );
//   }
//   return 1;
// };
