import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SchoolIcon from 'mdi-react/SchoolIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';

import AccountIcon from 'mdi-react/AccountIcon';
import LogoutIcon from '../../../images/log-out.svg';
import AlertIcon from '../../../images/AlertIcon.svg';
import CGUIcon from '../../../images/CGU-icon.svg';
import { NavBar } from '../../shared/nav-bar';
import { TextInput } from '../../ui-kit/text-input';
import { Switch } from '../../ui-kit/switch';
import { useUserAthentication } from '../../../helpers/context/auth';
import { Button } from '../../ui-kit/button';
import { editUserInfo } from '../../../api/index';

import styles from './settings.scss';

export const Settings = () => {
  const history = useHistory();
  const [changeEstablishment, setChange] = useState(false);
  const handleChangeInput = (e: any) => {
    console.log(e.target.value);
  };

  const {
    logOut,
    updateUser,
    user: {
      id,
      userSettings: { hasActiveNotification, id: settingsId },
      establishment: { name, postalCode },
    },
  } = useUserAthentication();

  return (
    <div className={styles.settingsContainer}>
      <NavBar />
      <div className={styles.content}>
        <div className={styles.settingsCard}>
          <div className={styles.header}>
            <SchoolIcon style={{ marginRight: '12px' }} />
            {/* Rejoindre un */}
            Mon établissement
          </div>
          {changeEstablishment ? (
            <>
              <TextInput
                value={''}
                placeholder={'Saisi le code de ton établissement'}
                type={'text'}
                name={'establishmentCode'}
                error={false}
                errorText={'entrez le code de ton établissement'}
                secondary={true}
                onChange={handleChangeInput}
                //   onBlur={handleBlurInput}
              />
              <Button
                className={styles.joinButton}
                value={`Demander à rejoindre l'équipe`}
                warning={true}
                onClick={() => setChange(false)}
              />
            </>
          ) : (
            <>
              <label className={styles.establishmentTitle}>
                {`${name} - ${postalCode}`}
              </label>
              {/* <label
                className={styles.changeEstablishment}
                onClick={() => setChange(true)}
              >
                Changer d’établissement
              </label> */}
            </>
          )}
        </div>
        <div className={styles.settingsCard}>
          <div
            className={styles.actionLabel}
            onClick={() => history.push('/account')}
          >
            <div className={styles.iconContainer}>
              <AccountIcon color={'#4a4a4a'} style={{ marginRight: '8px' }} />
              Compte
            </div>
            <ChevronRightIcon />
          </div>
          <div className={`${styles.actionLabel} ${styles.notification}`}>
            <div className={styles.iconContainer}>
              <img src={AlertIcon} alt={'*'} />
              Notifications
            </div>
            <Switch
              warning={true}
              checked={hasActiveNotification}
              id={'checkbox'}
              onChange={() => {
                if (id) {
                  editUserInfo(
                    {
                      settings: {
                        hasActiveNotification: !hasActiveNotification,
                        id: settingsId,
                      },
                    },
                    id,
                  )
                    .then((res: any) => {
                      updateUser({ ...res });
                    })
                    .catch((error) => {
                      throw error;
                    });
                }
              }}
              primary={true}
              className={styles.toggleButton}
            >
              <label className={styles.switchLabel}>
                {hasActiveNotification ? `activé` : `désactivé`}
              </label>
            </Switch>
          </div>
          <div
            className={styles.actionLabel}
            onClick={() => history.push('/term-of-services')}
          >
            <div className={styles.iconContainer}>
              <img src={CGUIcon} alt={'*'} />
              CGU
            </div>
            <ChevronRightIcon />
          </div>
          <div className={styles.actionLabel} onClick={() => logOut()}>
            <div className={styles.iconContainer}>
              <img src={LogoutIcon} alt={'*'} />
              Déconnexion
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
