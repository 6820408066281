import moment from 'moment-timezone';

export const getDateDiffByNow = (date: any) => {
  const diff = moment()
    .utc()
    .diff(
      new Date(
        moment(date.split('.')[0]).tz('Europe/Paris').format().split('+')[0],
      ),
      'millisecond',
    );
  const d = moment.duration(diff);
  const years = Math.abs(d.years());
  const months = Math.abs(d.months());
  const days = Math.abs(d.days());
  const hours = Math.abs(d.hours());
  const minutes = Math.abs(d.minutes());
  const seconds = Math.abs(d.seconds());
  if (years) {
    return `${years} an${years > 1 ? 's' : ''}`;
  } else if (months) {
    return `${months} mois`;
  } else if (days) {
    return `${days} jour${days > 1 ? 's' : ''}`;
  } else if (hours) {
    return `${hours} heure${hours > 1 ? 's' : ''}`;
  } else if (minutes) {
    return `${minutes} minute${minutes > 1 ? 's' : ''}`;
  } else if (seconds) {
    return `${seconds} seconde${seconds > 1 ? 's' : ''}`;
  }
};
