import { useState } from 'react';

import { IPostItem } from '../../helpers/lib/interfaces';
import { getPosts } from '../../api/index';

declare interface IPostsState {
  posts: IPostItem[];
  pages: {
    currentPage: number;
    totalPages: number;
  };
}
export const useSpeachPosts = () => {
  const [isFirstLoad, setFirstLoad] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [dataPosts, setDataPosts] = useState<IPostsState>({
    posts: [],
    pages: {
      currentPage: 1,
      totalPages: 1,
    },
  });
  const updatePostAsCompleted = (postId: number) => {
    const listOfPosts = [...dataPosts.posts];
    const postIndex = listOfPosts.findIndex((post) => post.id === postId);
    listOfPosts[postIndex].conversations = ['completed'];
    setDataPosts({
      ...dataPosts,
      posts: [...listOfPosts],
    });
  };
  const fetchPosts = () => {
    setLoadingState(true);
    getPosts(dataPosts.pages.currentPage, 5)
      .then((res: any) => {
        const {
          items,
          meta: { totalPages, currentPage },
        } = res;
        setDataPosts({
          ...dataPosts,
          posts: [...dataPosts.posts, ...items].sort(
            (postA: IPostItem, postB: IPostItem) =>
              new Date(postB.dateOfPublish).getTime() -
              new Date(postA.dateOfPublish).getTime(),
          ),
          pages: {
            ...dataPosts.pages,
            totalPages,
            currentPage: Number(currentPage) + 1,
          },
        });
        setLoadingState(false);
        setFirstLoad(false);
      })
      .catch((error) => {
        setLoadingState(false);
        setFirstLoad(false);
        console.log(error.message);
      });
  };
  const loadMorePosts = () => {
    if (loadingState) {
      return;
    }
    if (
      dataPosts.pages.currentPage <= dataPosts.pages.totalPages &&
      dataPosts.pages.currentPage > 1
    ) {
      fetchPosts();
    }
  };
  const loadPosts = () => {
    if (dataPosts.pages.currentPage === 1) {
      setFirstLoad(true);
      fetchPosts();
    }
  };

  return {
    isFirstLoad,
    loadPosts,
    loadMorePosts,
    updatePostAsCompleted,
    posts: dataPosts.posts,
    currentPage: dataPosts.pages.currentPage,
    totalPages: dataPosts.pages.totalPages,
  };
};
