import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import CheckIcon from 'mdi-react/CheckIcon';
import CloseIcon from 'mdi-react/ErrorOutlineIcon';
import { Spinner } from '../../../ui-kit/spinner';

import { AuthWrapper } from '../auth-wrapper';
import Peach from '../../../../images/peach.svg';
import PartyIcon from '../../../../images/Party Popper.svg';
import SadPeach from '../../../../images/sad-peach.png';
import { confirmAccount } from '../../../../api';
import getUrlParam from '../../../../helpers/utils/get-url-param';

import styles from './confirm-account.scss';

const ConfirmAccount = () => {
  const history = useHistory();
  const [isConfirmed, setConfirm] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    setLoading(true);
    confirmAccount(getUrlParam('token') || '')
      .then((res: any) => {
        setLoading(false);
        setConfirm(true);
        enqueueSnackbar(res.message || 'confirm avec succés', {
          variant: 'success',
          autoHideDuration: 2000,
        });
      })
      .catch((error) => {
        setLoading(false);
        setConfirm(false);
        enqueueSnackbar(
          error.message || 'un erreur se produite, verifier votre adresse mail',
          {
            variant: 'error',
            autoHideDuration: 2000,
          },
        );
      });
  }, []);
  return (
    <AuthWrapper>
      <form className={styles.signInFormContainer}>
        <div className={styles.titleContainer}>
          <label className={styles.title}>Hello Speach</label>
          <img src={Peach} alt={'peach'} />
        </div>
        <div className={styles.content}>
          {isLoading ? (
            <Spinner warning={true} className={styles.spinner} />
          ) : (
            <>
              <div className={styles.messageArea}>
                <div className={styles.iconContent}>
                  {isConfirmed ? (
                    <CheckIcon color={'green'} />
                  ) : (
                    <CloseIcon color={'red'} />
                  )}
                </div>
                <label style={{ color: isConfirmed ? 'green' : 'red' }}>
                  {isConfirmed
                    ? 'Félicitations !'
                    : `Votre compte n'a pas été confirmé !`}
                </label>
                <br />
                <label style={{ color: isConfirmed ? 'green' : 'red' }}>
                  {isConfirmed
                    ? 'Votre compte a bien été confirmé'
                    : ` Verifiez votre boite E-mail`}
                </label>
              </div>
              <img src={isConfirmed ? PartyIcon : SadPeach} alt={'*'} />
            </>
          )}
        </div>
        <div className={styles.returnLinkContainer}>
          <label
            className={`${styles.returnLink}`}
            onClick={() => {
              history.push('/sign-in');
            }}
          >
            Retour
          </label>
        </div>
      </form>
      {/* <img src={LoginBackground} alt={'*'} /> */}
    </AuthWrapper>
  );
};
export default ConfirmAccount;
