import { fetchPlus } from '../helpers/utils/fetch-plus';

const baseUrls = {
  local: 'https://api.speachbot.fr/api',
  stag: 'https://api.speachbot.fr/api',
  prod: 'https://api.speachbot.fr/api',
};

const isLocalhost = window.location.hostname.indexOf('localhost') !== -1;
const isStaging = window.location.hostname.indexOf('nip.io') !== -1;

export const BASE_URL = baseUrls[isLocalhost ? 'local' : isStaging ? 'stag' : 'prod'];

const headers = {
  'Content-Type': 'application/json',
  mode: 'no-cors',
  'Access-Control-Allow-Origin': '*',
};

/*** Athentications queries ***/

export const signIn = (email: string, password: string) => {
  return fetchPlus(`${BASE_URL}/users/sign-in`, {
    headers: {
      ...headers,
      'app-domain': 'app',
    },
    method: 'POST',
    body: JSON.stringify({ email, password }),
  });
};
export const signUp = (body: any) => {
  return fetchPlus(`${BASE_URL}/users/sign-up`, {
    headers,
    method: 'POST',
    body: JSON.stringify(body),
  });
};
export const recoverPassword = (body: any) => {
  return fetchPlus(`${BASE_URL}/users/reset-password`, {
    headers: {
      ...headers,
      'app-domain': 'app',
    },
    method: 'PATCH',
    body: JSON.stringify(body),
  });
};
export const confirmAccount = (token: string) => {
  return fetchPlus(`${BASE_URL}/users/confirm-account/${token}`, {
    headers: {
      ...headers,
    },
    method: 'GET',
  });
};
/*** fetch with Authorisations queries ***/

export const meQuery = () => {
  return fetchPlus(`${BASE_URL}/users/me`, {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    method: 'GET',
  });
};
export const editUserInfo = (body: any, id: number) => {
  return fetchPlus(`${BASE_URL}/users/${id}`, {
    headers: {
      ...headers,
      'app-domain': 'app',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    method: 'PATCH',
    body: JSON.stringify(body),
  });
};

/* fetch posts */
export const getPosts = (page: number, limit: number) => {
  return fetchPlus(`${BASE_URL}/posts/?page=${page}&limit=${limit}`, {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    method: 'GET',
  });
};
/* chat */

export const postConversation = (postId: string) => {
  return fetchPlus(`${BASE_URL}/chat/conversations/${postId}`, {
    headers: {
      ...headers,
      'app-domain': 'app',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    method: 'POST',
    // body: JSON.stringify(body),
  });
};
export const replyQuestion = (
  conversationId: string | null,
  questionId: string | null,
  qrId: string | null,
) => {
  return fetchPlus(
    `${BASE_URL}/chat/conversations/${conversationId}/questions/${questionId}/quick-replies/${qrId}`,
    {
      headers: {
        ...headers,
        'app-domain': 'app',
        Authorization: 'Bearer ' + localStorage.getItem('token'),
      },
      method: 'POST',
      // body: JSON.stringify(body),
    },
  );
};

export const getStories = () => {
  return fetchPlus(`${BASE_URL}/stories`, {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'app-domain': 'app',
    },
    method: 'GET',
  });
};

/* ask question */
export const postQuestion = (storyQuestion: string) => {
  return fetchPlus(`${BASE_URL}/stories/ask`, {
    headers: {
      ...headers,
      'app-domain': 'app',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    method: 'POST',
    body: JSON.stringify({
      storyQuestion,
    }),
  });
};
export const incrementStoryVueNumber = (storyId: number, body: any) => {
  return fetchPlus(`${BASE_URL}/stories/${storyId}`, {
    headers: {
      ...headers,
      'app-domain': 'app',
      Authorization: 'Bearer ' + localStorage.getItem('token'),
    },
    method: 'PATCH',
    body: JSON.stringify(body),
  });
};
