import React from 'react';
import ReactDOM from 'react-dom';
import { IntentProps } from '../../../helpers/lib/interfaces';
import { Dialog } from './dialog';

declare interface DialogProps extends IntentProps {
  children: React.ReactNode;
  className?: string;
  onClose?: EventListener;
  animation?: 'fadeIn' | 'scaleUp' | 'none';
  defaultClose?: boolean;
}

export const DialogService = (props: DialogProps) => {
  const createDialog = (props: DialogProps) => {
    if (typeof props.children !== 'string' && !Array.isArray(props.children)) {
      props.children = [props.children];
    }

    const newModal = React.createElement(
      Dialog,
      { open: true, ...props },
      props.children,
    );
    const fakeContainer = document.createElement('div', {});
    ReactDOM.render(newModal, fakeContainer);
  };
  return createDialog(props);
};
