import React from 'react';

import styles from './stories-menu-card.scss';

import SpeachBotIcon from '../../../../images/avatar-Speach.svg';
import BiologicStory from '../../../../images/biologic-story.jpg';
import PsycoStory from '../../../../images/psyco-story.jpg';
import SocialStory from '../../../../images/social-story.jpg';
import IntroStory from '../../../../images/intro-story.jpg';
import QuestionStory from '../../../../images/question-icon.jpg';
declare interface IStoriesMenuCard {
  showTemporaryStories: () => void;
  showPresentationStories: () => void;
  showBiologicStories: () => void;
  showPsycoStories: () => void;
  showSocialStories: () => void;
  showQuestionStories: () => void;
  temporaryStory?: any[];
}
export const StriesMenuCard = ({
  showTemporaryStories,
  showPresentationStories,
  showBiologicStories,
  showPsycoStories,
  showSocialStories,
  showQuestionStories,
  temporaryStory = [],
}: IStoriesMenuCard): React.ReactElement<IStoriesMenuCard> => {
  return (
    <div className={styles.storiesMenuContainer}>
      <img
        src={SpeachBotIcon}
        className={`${
          temporaryStory.length &&
          temporaryStory.some((story) => !story.isSeen) &&
          styles.newStory
        }`}
        onClick={showTemporaryStories}
      />
      <div className={styles.footerContainer}>
        <div className={styles.storyItem}>
          <div
            className={styles.imgContainer}
            onClick={showPresentationStories}
          >
            <img src={IntroStory} alt={'*'} />
          </div>
          Présentation
        </div>
        <div className={styles.storyItem}>
          <div className={styles.imgContainer} onClick={showBiologicStories}>
            <img src={BiologicStory} alt={'*'} />
          </div>
          Biologique
        </div>
        <div className={styles.storyItem}>
          <div className={styles.imgContainer} onClick={showPsycoStories}>
            <img src={PsycoStory} alt={'*'} />
          </div>
          Psycho-affectif
        </div>
        <div className={styles.storyItem}>
          <div className={styles.imgContainer} onClick={showSocialStories}>
            <img src={SocialStory} alt={'*'} />
          </div>
          Social
        </div>
        <div className={styles.storyItem}>
          <div className={styles.imgContainer}>
            <img src={QuestionStory} alt={'*'} onClick={showQuestionStories} />
          </div>
          Une question ?
        </div>
      </div>
    </div>
  );
};
