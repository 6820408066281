import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';

import styles from './dialog.scss';

export interface DialogProps {
  children?: React.ReactNode;
  className?: string;
  open: boolean;
  onClose?: EventListener;
  animation?: 'scaleUp' | 'fadeIn' | 'none';
  defaultClose?: boolean;
}

export const Dialog = ({
  children,
  className = '',
  open = false,
  onClose,
  animation = 'fadeIn',
  defaultClose = true,
  ...rest
}: DialogProps): React.ReactElement<DialogProps> | null => {
  const props = {
    className: classnames(styles.dialog, className, {
      [styles.animationFadeIn]: animation === 'fadeIn',
      [styles.animationScaleUp]: animation === 'scaleUp',
    }),
    ...rest,
  };
  const [isOpen, setIsOpen] = useState(open);
  const ref = React.createRef<HTMLDivElement>();

  const close = () => {
    if (!ref.current) {
      return;
    }
    if (onClose) {
      ref.current.addEventListener('CloseModal', onClose);
    }
    if (
      ref.current.dispatchEvent(
        new CustomEvent('CloseModal', { cancelable: true }),
      )
    ) {
      setIsOpen(false);
    }
  };

  const dropClickHandler = () => {
    close();
  };

  const escClickHandler = (event: React.KeyboardEvent) => {
    if (event.keyCode !== 27) {
      return;
    }
    close();
  };

  const dialogOnClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  useEffect(() => {
    setIsOpen(open);
    /**
     * focus emulation strategy, created to make sure that the top dialog is always on focus
     * still not the best solution but it works
     * setTimeout was required since we use portal here so selecting dialog elemnts need to wait until the dialogs
     * appended to the DOM tree.
     */
    setTimeout(() => {
      const modals: NodeListOf<Element> = document.querySelectorAll(
        '[role="dialog"]',
      );
      const lastFocusableElement: Element = modals[modals.length - 1];
      if (lastFocusableElement) {
        (lastFocusableElement as HTMLElement).focus();
      }
    }, 0);

    // Remove event listener on cleanup
    return () => {
      if (onClose && ref.current) {
        ref.current.removeEventListener('CloseModal', onClose);
      }
    };
  }, [open]);

  const dialog = (
    <div
      ref={ref}
      className={styles.dialogWrapper}
      onKeyDown={escClickHandler}
      onClick={dropClickHandler}
    >
      <div
        {...props}
        onClick={dialogOnClick}
        tabIndex={-1}
        role="dialog"
        aria-label="modal"
        aria-modal="true"
      >
        {children}
      </div>
    </div>
  );

  return isOpen ? ReactDOM.createPortal(dialog, document.body) : null;
};
