import React from 'react';

import styles from './advertisements.scss';
import Pub1 from '../../../../images/amis_speach_pub1.png';
import Pub2 from '../../../../images/amis_speach-03.png';
export const Advertisements = (): React.ReactElement => {
  return (
    <div className={styles.advertisements}>
      <label>les ami.e.s de Speach</label>
      <div className={styles.bloc}>
        <img src={Pub1} alt="les amis du speach" />
      </div>
      <div className={styles.bloc}>
        <img src={Pub2} alt="les amis du speach" />
      </div>
    </div>
  );
};
