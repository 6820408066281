import React from 'react';
import { useHistory } from 'react-router-dom';

import Peach from '../../../images/avatar-Speach.svg';

import styles from './term-of-service.scss';

const TermOfService = (): React.ReactElement => {
  const history = useHistory();
  return (
    <div className={styles.cguContainer}>
      <div className={styles.cguContent}>
        <div className={styles.titleContainer}>
          <img src={Peach} alt={'peach'} />
          <label className={styles.title}>
            conditions générales d'utilisation
          </label>
        </div>
        <span className={styles.title}>Résumé des CGU</span>
        <p className={styles.cgu}>
          Accéder aux CGU complètes ici{' '}
          <a href="/CGU.pdf" target="_blank">
            speachbot-CGU.pdf
          </a>{' '}
          Version du 21/04/2021
        </p>
        <span className={`${styles.title} ${styles.legal}`}>
          Mentions légales
        </span>
        <span className={styles.subTitle}>Editeur :</span>
        <p>
          Le service Internet disponible à l’adresse{' '}
          <a href="https://speachbot.fr/" target="_blank">
            https://speachbot.fr
          </a>{' '}
          est édité par l’association loi 1901 Speach Prévention Sexualité,
          immatriculée au RNA sous le numéro W751245455 et dont le siège social
          est situé 4 rue des Mariniers, 75014 Paris.
          <ul>
            <li>Numéro de téléphone : 0756876693.</li>
            <li>Adresse email : speach.team@gmail.com .</li>
            <li>Directeur de la publication : Mme Lecoffre Sarah </li>
            <li>
              Hébergeur: Le Site est hébergé auprès de la société OVH SAS Siège
              social : 2 rue Kellermann - 59100 Roubaix - France
            </li>
          </ul>
        </p>
        <span className={styles.subTitle}>Champs d’application</span>
        <p>
          Les présentes CGU sont en langue française s’appliquent à tout
          Utilisateur souhaitant naviguer sur le Site et utiliser les Services.
          A ce titre, toute utilisation du Site par un Utilisateur emporte de
          plein droit l’acceptation pleine et entière des CGU, ainsi que, dans
          le cas où l’Utilisateur accepte de communiquer à Speach des Données à
          caractère personnel : nom, prénom, date de naissance, établissement
          scolaire.
        </p>
        <span className={styles.subTitle}>Description des Services</span>
        <p>
          <span className={styles.subTitle}>Speach</span> est une association à
          but non-lucratif créant un dispositif d’éducation à la sexualité
          destiné à un public principalement mineur, et vise à être un appui à
          la mise en place des 3 séances d'éducation à la sexualité obligatoires
          prévues par l'’article L312-16 du code d’éducation qui prévoit : « Une
          information et une éducation à la sexualité sont dispensées dans les
          écoles, les collèges et les lycées à raison d'au moins trois séances
          annuelles et par groupes d'âge homogène ». Le Site donne accès au
          Service conversationnel qui permet aux Utilisateurs de discuter avec
          le chatbot Speachbot sur des sujets définis selon 3 thématiques : la
          biologie, le psycho-affectif et le social. Il est permis aux
          Utilisateurs de déposer des questions sur ce sujet afin que Speach y
          réponde. Il est précisé que les contenus du chatbot sont relus par le
          Crips Ile-de-France, partenaire de Speach, qui identifie et forme
          également les établissements scolaires participants à cette
          expérimentation financée par la Région Ile-de-France. Les Services de
          Speach sont uniquement accessibles à partir d’un code d’utilisation
          fourni dans le cadre scolaire.
        </p>
        <span className={styles.return} onClick={() => history.goBack()}>
          {'<'} Retour
        </span>
        <label className={styles.copyRight}>
          © Copyright 2020. All Rights Reserved. Last Update: 2021-04-28
        </label>
      </div>
    </div>
  );
};
export default TermOfService;
