import React, { useState } from 'react';
import classnames from 'classnames';
import { IntentProps } from '../../../helpers/lib/interfaces';
import styles from './checkbox.scss';

export interface CheckboxProps extends IntentProps {
  id?: string;
  className?: string;
  checked?: boolean;
  onChange?: (value: boolean) => void;
  children?: React.ReactNode;
  disabled?: boolean;
}

export const Checkbox = ({
  onChange,
  disabled = false,
  checked = false,
  children,
  primary = true,
  secondary = false,
  success = false,
  warning = false,
  error = false,
  info = false,
  className = '',
  ...rest
}: CheckboxProps): React.ReactElement<CheckboxProps> => {
  const props = {
    className: classnames(
      styles.checkbox,
      className,
      {
        [styles.checkboxPrimary]: primary,
        [styles.checkboxSecondary]: secondary,
        [styles.checkboxSuccess]: success,
        [styles.checkboxWarning]: warning,
        [styles.checkboxError]: error,
        [styles.checkboxInfo]: info,
      },
      {
        [styles.checkboxDisabled]: disabled,
      },
    ),
    ...rest,
  };
  const [isChecked, setCheckbox] = useState(checked);
  const toggleCheckbox = () => {
    const willCheck = !isChecked;
    setCheckbox(willCheck);
    onChange && onChange(willCheck);
  };
  return (
    <div
      {...(props as React.HTMLProps<HTMLDivElement>)}
      onClick={toggleCheckbox}
    >
      <input type={'checkbox'} checked={isChecked} />
      <div className={styles.checkboxBackground}>
        <svg viewBox={'0 0 20 20'}>
          <path
            fill={'none'}
            d={
              'M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z'
            }
          />
          <polyline points="4 11 8 15 16 6"></polyline>
        </svg>
      </div>
      {children}
    </div>
  );
};
