import React from 'react';
import WarningIcon from 'mdi-react/WarningIcon';

import { Button } from '../../components/ui-kit/button';
import { CheckToken } from './check-token';
import { DialogService } from '../../components/ui-kit/dialog';
/**
 *
 * @param response
 * error handling generic
 */

export const handleErrors = (response: Response) => {
  if (response.status === 204) return JSON.stringify(response);
  if (!response.ok) throw response;
  return response.json();
};

// NOTE : this not yet finished

/**
 * custom fetch to handel errors correctly and take care of showing & hidding loader automatically
 * @param url API URL
 * @param options Fetch regular option
 */
const fetchMethod = (url: string, options: any) => {
  //FEAT : show loader
  return fetch(encodeURI(url), options)
    .then((response: any) => {
      // FEAT : Hide loader
      return handleErrors(response);
    })
    .catch((error) => {
      // treat the case of 500 error (it's not json response so we drop 500 as string)
      if (error.status === 500) {
        throw error;
      }
      // treat the case of over size of file to upload
      if (error.status === 413) {
        throw error; //'The consent file is too large (over 2MB)';
      }
      if (error.json) {
        // the case of 4** error , it's json so we need to return the response in the next .catch
        return error.json().then((body: any) => {
          if (body.message && body.message === 'is_not_active') {
            DialogService({
              children: (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      color: '#F5A623',
                      backgroundColor: 'antiquewhite',
                      borderRadius: '8px',
                      padding: '16px',
                    }}
                  >
                    <WarningIcon color={'#F5A623'} />
                    <label>Attention !!</label>
                  </div>
                  <div
                    style={{
                      height: '38vh',
                      backgroundColor: 'rgb(242, 243, 245)',
                      marginTop: '-8px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '16px',
                      }}
                    >
                      <b style={{ color: 'coral' }}>
                        Impossible de se connecter
                      </b>
                      <br />
                      <p
                        style={{
                          padding: '.25rem 0 .75rem',
                          fontSize: '0.8em',
                        }}
                      >
                        Votre compte est désativé. Veuillez contacter votre
                        établissement,clickez sur 'ok' pour se connecter
                      </p>
                    </div>
                    <div style={{ margin: 'auto', width: '40%' }}>
                      <Button
                        value={'OK'}
                        warning={true}
                        onClick={() => {
                          localStorage.setItem('token', '');
                          window.location.href = '/sign-in';
                        }}
                      />
                    </div>
                  </div>
                </div>
              ),
              onClose: () => {
                window.location.href = '/sign-in';
              },
            });
          }
          throw body;
        });
      } else throw error;
    });
};
export const fetchPlus = (url: string, options: any) => {
  if (options.headers.Authorization) {
    if (!CheckToken(localStorage.getItem('token') || '')) {
      DialogService({
        children: (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: '#F5A623',
                backgroundColor: 'antiquewhite',
                borderRadius: '8px',
                padding: '16px',
              }}
            >
              <WarningIcon color={'#F5A623'} />
              <label>Attention !!</label>
            </div>
            <div
              style={{
                height: '38vh',
                backgroundColor: 'rgb(242, 243, 245)',
                marginTop: '-8px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  padding: '16px',
                }}
              >
                <b style={{ color: 'coral' }}>Impossible de se connecter</b>
                <br />
                <p style={{ padding: '.25rem 0 .75rem', fontSize: '0.8em' }}>
                  votre session à été expiré,clickez sur 'ok' pour se connecter
                </p>
              </div>
              <div style={{ margin: 'auto', width: '40%' }}>
                <Button
                  value={'OK'}
                  warning={true}
                  onClick={() => {
                    window.location.href = '/sign-in';
                  }}
                />
              </div>
            </div>
          </div>
        ),
        onClose: () => {
          window.location.href = '/sign-in';
        },
      });
      const promise = new Promise((resolve, reject) => {
        return reject();
      });
      return promise;
    } else {
      return fetchMethod(url, options);
    }
  } else {
    return fetchMethod(url, options);
  }
};
