import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import AlertIcon from 'mdi-react/AlertCircleIcon';
import InstagramIcon from 'mdi-react/InstagramIcon';
import ArrowLeft from 'mdi-react/ArrowLeftIcon';
import ArrowRight from 'mdi-react/ArrowRightIcon';

import SettingsIcon from 'mdi-react/SettingsOutlineIcon';
import { Avatar } from '../../ui-kit/avatar';
import PeachStoriesmenu from '../../../images/avatar-Speach.svg';
import { useUserAthentication } from '../../../helpers/context/auth';
import { meQuery } from '../../../api/index';

import styles from './nav-bar.scss';

export const NavBar = (): React.ReactElement => {
  const location = useLocation();
  const currentPath = location.pathname;
  const {
    user: { id, firstName, lastName, hasConfirmedAccount },
    updateUser,
  } = useUserAthentication();
  useEffect(() => {
    if (id === null)
      meQuery()
        .then((res: any) => {
          updateUser(res);
        })
        .catch((error) => {
          throw error;
        });
  }, []);
  return (
    <>
      <div className={styles.navBar}>
        {currentPath === '/home' ? (
          <Link to={'/settings'}>
            <div className={styles.messageIcon}>
              <SettingsIcon
                size={24}
                style={{
                  color: '#767676',
                }}
              />
            </div>
          </Link>
        ) : currentPath === '/settings' ? (
          <label className={`${styles.navBarTitle} ${styles.settingsTitle}`}>
            Réglages
          </label>
        ) : (
          <Link to={'/home'} className={styles.link}>
            <div className={styles.imgContainer}>
              <ArrowLeft color="#4a4a4a" />
              <img
                className={styles.imgDiscussion}
                src={PeachStoriesmenu}
                alt={'*'}
              />
              <label
                className={`${styles.navBarTitle} ${styles.settingsTitle}`}
              >
                {currentPath === '/account' ? 'Compte' : 'Discussion'}
              </label>
            </div>
          </Link>
        )}
        {currentPath === '/home' && (
          <label className={`${styles.navBarTitle} ${styles.homeTitle}`}>
            FIL
          </label>
        )}
        {currentPath === '/home' && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a
              href={'https://www.instagram.com/speachbot/?hl=en'}
              target={'_blank'}
            >
              <InstagramIcon
                style={{
                  marginRight: '8px',
                  color: '#9B9B9B',
                  cursor: 'pointer',
                }}
              />
            </a>
            <Link to={'/account'} className={styles.link}>
              <Avatar name={`${firstName || 'A'} ${lastName || 'Z'}`} />
            </Link>
          </div>
        )}
        {currentPath === '/settings' && (
          <Link to={'/home'} className={styles.link}>
            <div className={styles.imgContainer}>
              <img src={PeachStoriesmenu} alt={'*'} />
              <ArrowRight color="#4a4a4a" />
            </div>
          </Link>
        )}
      </div>
      {hasConfirmedAccount === false && (
        <div
          className={styles.navBar}
          style={{
            top: '52px',
            backgroundColor: 'rgb(255,0,0,0.6)',
            color: 'white',
            padding: '8px',
            height: 'fit-content',
            flexFlow: 'column',
            zIndex: 99,
          }}
        >
          <AlertIcon color="white" size={24} />
          <p>
            votre compte n'a pas encore activé, vous avez reçu un mail
            d'activation,merci de verfier votre boite mail pour l'activer
          </p>
        </div>
      )}
    </>
  );
};
