import React, { useState } from 'react';
import { useSnackbar } from 'notistack';

import { Button } from '../../../ui-kit/button';
import { postQuestion } from '../../../../api';

import styles from './question-box.scss';

declare interface QuestionBoxProps {
  onSentQuestion?: () => void;
}
export const QuestionsBox = ({ onSentQuestion }: QuestionBoxProps) => {
  const [storiesQuestion, askQuestion] = useState('');
  const [isLoading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  return (
    <div className={styles.questionContainer}>
      <label>Une question sur le sexe ou l’amour ?</label>
      <input
        type={'text'}
        className={styles.questionInput}
        value={storiesQuestion}
        placeholder={`Ecris ici`}
        onChange={(e) =>
          e.target.value.length <= 160 && askQuestion(e.target.value)
        }
      />
      <label
        className={`${styles.sticker}  ${
          storiesQuestion.length >= 150 && styles.warning
        } ${storiesQuestion.length === 160 && styles.danger}`}
      >
        {storiesQuestion.length}/160
      </label>
      <Button
        className={styles.editButton}
        value={'Envoyer'}
        warning={true}
        loading={isLoading}
        onClick={() => {
          if (storiesQuestion) {
            setLoading(true);
            postQuestion(storiesQuestion)
              .then((res: any) => {
                setLoading(false);
                askQuestion('');
                if (onSentQuestion) onSentQuestion();
                if (res.id) {
                  enqueueSnackbar('votre question a été bien envoyé', {
                    variant: 'success',
                    autoHideDuration: 2000,
                  });
                }
              })
              .catch((error) => {
                setLoading(false);
                askQuestion('');
                if (onSentQuestion) onSentQuestion();
                if (error) {
                  enqueueSnackbar(
                    error.message || 'un erreur se produite de serveur',
                    {
                      variant: 'error',
                      autoHideDuration: 2000,
                    },
                  );
                }
              });
          }
        }}
      />
    </div>
  );
};
