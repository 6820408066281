import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ChevronDown from 'mdi-react/ChevronDownIcon';

import { NavBar } from '../../shared/nav-bar';
import {
  SpeachMessage,
  UserMessage,
  ResponsesSelector,
} from '../../shared/discussions-components';
import SpeachBotIcon from '../../../images/avatar-Speach.svg';
import SadPEach from '../../../images/sad-peach.png';
import { postConversation, replyQuestion } from '../../../api';
import { useSpeachPostsContext } from '../../../helpers/context/speach-posts';

import styles from './discussions.scss';

export const Discussions = (): React.ReactElement => {
  const { updatePostAsCompleted } = useSpeachPostsContext();
  const { postId } = useParams() as {
    postId: string;
  };
  const [conversationData, setConversationData] = useState({
    conversationId: null,
    questionId: null,
  });
  const [quickReplies, setQuickReplies] = useState<any>([]);
  const [speachMessages, setSpeachMessages] = useState<any>([]);
  const [isSpeachLoading, setSpeachLoading] = useState(false);
  const [noConversation, setEmptyConversation] = useState(false);
  const [openedListIndex, setOpenedListIndex] = useState<number[]>([]);
  const selectMessage = (response: any) => {
    const { conversationId, questionId } = conversationData;
    setSpeachMessages([
      ...speachMessages,
      {
        from: 'client',
        text: response.content,
      },
    ]);
    setSpeachLoading(true);
    replyQuestion(conversationId, questionId, response.id).then((res: any) => {
      if (res) {
        let i = 0;
        setTimeout(() => {
          setSpeachMessages([
            ...speachMessages,
            {
              from: 'client',
              text: response.content,
            },
            {
              from: 'speach',
              text: [res.content.split('$$@SPEACH@$$')[i]],
            },
          ]);
          window.scrollTo(0, document.body.scrollHeight);
          i = i + 1;
          if (res.content.split('$$@SPEACH@$$').length > 1 && i === 1)
            lauchTimer();
          if (res.content.split('$$@SPEACH@$$').length === 1)
            setSpeachLoading(false);
        }, 4000);

        const lauchTimer = () => {
          const Timer = setInterval(() => {
            setSpeachMessages((speachMessages: any) => {
              const speachMessagesCopy = [...speachMessages];
              speachMessagesCopy[speachMessagesCopy.length - 1].text = [
                ...speachMessagesCopy[speachMessagesCopy.length - 1].text,
                res.content.split('$$@SPEACH@$$')[i],
              ];
              return [...speachMessagesCopy];
            });
            if (i < res.content.split('$$@SPEACH@$$').length - 1) {
              window.scrollTo(0, document.body.scrollHeight);
              i = i + 1;
            } else {
              clearInterval(Timer);
              setSpeachLoading(false);
            }
          }, 4000);
        };
        setQuickReplies(
          res.quickReplies.map((qr: any) => ({
            content: qr.content,
            id: qr.id,
          })),
        );
        setConversationData({
          ...conversationData,
          questionId: res.id,
        });
        if (!res.quickReplies.length) updatePostAsCompleted(Number(postId));
      }
    });
    window.scrollTo(0, document.body.scrollHeight);
  };
  useEffect(() => {
    postConversation(postId)
      .then((res: any) => {
        if (res.rootQuestion) {
          setQuickReplies(
            res.rootQuestion.quickReplies.map((qr: any) => ({
              content: qr.content,
              id: qr.id,
            })),
          );
          setConversationData({
            ...conversationData,
            conversationId: res.conversation.id,
            questionId: res.rootQuestion.id,
          });
          setSpeachMessages([
            {
              from: 'speach',
              text: res.rootQuestion.content.split('$$@SPEACH@$$'),
            },
          ]);
        } else if (res.questionsAndAnswers) {
          const { questionsAndAnswers } = res;
          const qstAnsList: any = [];
          questionsAndAnswers.map((qstAns: any, ind: number) => {
            qstAnsList.push({
              from: 'speach',
              text: qstAns.question.content.split('$$@SPEACH@$$'),
            });
            qstAnsList.push({
              from: 'user',
              text: qstAns.quickReply.content,
            });
            if (
              ind === questionsAndAnswers.length - 1 &&
              qstAns.quickReply.nextQuestion
            )
              qstAnsList.push({
                from: 'speach',
                text: qstAns.quickReply.nextQuestion.content.split(
                  '$$@SPEACH@$$',
                ),
              });
            if (
              qstAns.quickReply.nextQuestion &&
              qstAns.quickReply.nextQuestion.quickReplies
            ) {
              setQuickReplies([...qstAns.quickReply.nextQuestion.quickReplies]);
            }
            setSpeachMessages([...qstAnsList]);
          });
        }
        if (res.questionsAndAnswers && res.questionsAndAnswers.length) {
          setConversationData({
            ...conversationData,
            conversationId: res.questionsAndAnswers[0].conversation.id,
            questionId:
              res.questionsAndAnswers[res.questionsAndAnswers.length - 1]
                .quickReply.nextQuestion.id || null,
          });
        }
        if (res.statusCode === 404) setEmptyConversation(true);
        window.scrollTo(0, document.body.scrollHeight);
      })
      .catch((err) => {
        setEmptyConversation(true);
      });
  }, [postId]);
  useEffect(() => {
    window.scrollTo(0, document.body.scrollHeight);
  }, [isSpeachLoading]);
  const handleToggleItem = (index: number) => {
    if (openedListIndex.findIndex((ind) => ind === index) >= 0) {
      const openedListIndexCopy = [...openedListIndex];
      openedListIndexCopy.splice(
        openedListIndex.findIndex((ind) => ind === index),
        1,
      );
      setOpenedListIndex([...openedListIndexCopy]);
    } else {
      setOpenedListIndex([...openedListIndex, index]);
    }
  };
  const isContain = (index: number) =>
    openedListIndex.findIndex((ind) => ind === index) >= 0;
  return (
    <div className={styles.wrapper}>
      <NavBar />
      <div className={styles.content}>
        <div className={styles.side}>
          <div className={styles.leftSide}>
            <label className={styles.usedInfo}>Infos utiles</label>
            <div className={styles.usedInfoCard}>
              <label className={styles.question}>
                Besoin de parler de santé, sexualité, mal-être ?
              </label>
              <label className={styles.response}>Fil Santé Jeunes :</label>
              <label className={styles.number}>0 800 235 236</label>
              <label className={styles.greenLabel}>
                numéro vert, anonyme et gratuit
              </label>
            </div>
            <div className={styles.usedInfoCard}>
              <label className={styles.question}>
                Besoin d’un conseil juridique ?
              </label>
              <label className={styles.response}>
                Jeunes Violences Écoute :
              </label>
              <label className={styles.number}>0808 807 700</label>
              <label className={styles.greenLabel}>
                numéro vert, anonyme et gratuit
              </label>
            </div>
            <div className={styles.usedInfoCard}>
              <label className={styles.question}>
                Toi ou un.e ami.e est en danger ?
              </label>
              <label className={styles.response}>
                Allô Enfance en Danger :
              </label>
              <label className={styles.number}>119</label>
              <label className={styles.greenLabel}>
                numéro d'urgence gratuit, 24h/24
              </label>
            </div>
          </div>
        </div>
        <div className={styles.discussionContainer}>
          {noConversation ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                margin: 'auto',
                alignItems: 'center',
              }}
            >
              <img
                src={SadPEach}
                style={{ width: '60px', height: '60px', marginBottom: '16px' }}
              />
              <label style={{ color: '#767676' }}>
                Pas de conversation encore
              </label>
            </div>
          ) : (
            <div className={styles.messagesContainer}>
              {/* <label className={styles.timeContent}>15:40</label> */}
              {speachMessages.map((message: any, ind: number) =>
                message.from === 'speach' ? (
                  <SpeachMessage text={message.text} key={ind} />
                ) : (
                  <UserMessage
                    text={
                      (typeof message.text === 'string' && message.text) || ''
                    }
                    key={ind}
                  />
                ),
              )}
              {isSpeachLoading ? (
                <div className={styles.typingIndicator}>
                  <span />
                  <span />
                  <span />
                </div>
              ) : (
                <ResponsesSelector
                  onClick={selectMessage}
                  responses={quickReplies}
                />
              )}
            </div>
          )}
        </div>
        <div className={styles.side}>
          <div className={styles.rightSide}>
            <div className={styles.headerDiscussionMenu}>
              <img src={SpeachBotIcon} alt={'*'} />
              <label className={styles.menueTitle}>Speachbot</label>
              <label className={styles.status}>En ligne</label>
            </div>
            <div className={styles.menuContainer}>
              <label className={styles.menuTitle}>FAQ</label>
              <div
                className={styles.itemLabel}
                onClick={() => handleToggleItem(0)}
              >
                <label className={styles.menuQuestion}>
                  🤔 Qui es-tu Speach ?
                </label>
                <div
                  className={`${styles.buttonIcon} ${
                    isContain(0) ? styles.rotate : styles.return
                  }`}
                >
                  <ChevronDown color={'#4a4a4a'} />
                </div>
              </div>
              <div
                className={`${styles.info} ${
                  isContain(0) ? styles.show : styles.hidden
                }`}
              >
                <p>
                  Je suis un chatbot , c’est-à-dire un programme informatique
                  auquel on a appris son texte.
                </p>
              </div>
              <div
                className={styles.itemLabel}
                onClick={() => handleToggleItem(1)}
              >
                <label className={styles.menuQuestion}>
                  💪 Comment discuter ensemble ?
                </label>
                <div
                  className={`${styles.buttonIcon} ${
                    isContain(1) ? styles.rotate : styles.return
                  }`}
                >
                  <ChevronDown color={'#4a4a4a'} />
                </div>
              </div>
              <div
                className={`${styles.info} ${
                  isContain(1) ? styles.show : styles.hidden
                }`}
              >
                <p>
                  Clique sur le bouton de réponse rapide qui ressemble le plus à
                  ce que tu penses.
                </p>
              </div>
              <div
                className={styles.itemLabel}
                onClick={() => handleToggleItem(2)}
              >
                <label className={styles.menuQuestion}>
                  👩‍🏫 Pourquoi une éducation à la sexualité ?
                </label>
                <div
                  className={`${styles.buttonIcon} ${
                    isContain(2) ? styles.rotate : styles.return
                  }`}
                >
                  <ChevronDown color={'#4a4a4a'} />
                </div>
              </div>
              <div
                className={`${styles.info} ${
                  isContain(2) ? styles.show : styles.hidden
                }`}
              >
                <p>
                  L’éducation à la sexualité est un enseignement obligatoire
                  depuis 2001 en France.
                </p>
              </div>
              <div
                className={styles.itemLabel}
                onClick={() => handleToggleItem(3)}
              >
                <label className={styles.menuQuestion}>
                  🧐 Que voit mon établissement ?
                </label>
                <div
                  className={`${styles.buttonIcon} ${
                    isContain(3) ? styles.rotate : styles.return
                  }`}
                >
                  <ChevronDown color={'#4a4a4a'} />
                </div>
              </div>
              <div
                className={`${styles.info} ${
                  isContain(3) ? styles.show : styles.hidden
                }`}
              >
                <p>
                  Ton établissement ne voit pas les réponses rapides que tu
                  cliques, il saura le nombre d’élèves ayant fini de discuter
                  avec moi sur le post sans savoir qui.
                </p>
              </div>
            </div>
            {/* <div className={styles.helpContainer}>
              <label className={styles.helpTitle}>ASSISTANCE</label>
              <div className={styles.helpItem}>
                <label>Rechecher dans la discussion</label>
                <div className={styles.imageContainer}>
                  <img src={HelpIcon} alt={'*'} />
                </div>
              </div>
              <div className={styles.helpItem}>
                <label>Signaler un problème</label>
                <div className={styles.imageContainer}>
                  <img src={FlagIcon} alt={'*'} />
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
