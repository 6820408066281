import { useState } from 'react';

import { CheckToken } from '../../helpers/utils/check-token';
import { IUserProps } from '../../helpers/lib/interfaces';
import { initialUser } from '../../helpers/consts/initial-user-state';

export const useUserAuth = () => {
  const [isAuthenticated, setAuthentication] = useState(
    CheckToken(localStorage.getItem('token') || ''),
  );
  const [user, setUserData] = useState({ ...initialUser });
  const logIn = (token: string, userData: IUserProps) => {
    if (!CheckToken(token)) {
      setAuthentication(false);
    } else {
      setUserData(JSON.parse(JSON.stringify(userData)));
      localStorage.setItem('token', token);
      setAuthentication(true);
    }
  };
  const logOut = () => {
    localStorage.setItem('token', '');
    setAuthentication(false);
  };
  const updateUser = (userData: IUserProps) => {
    setUserData(JSON.parse(JSON.stringify(userData)));
  };
  return { logIn, logOut, isAuthenticated, user, updateUser };
};
