import React from 'react';
import { Dialog } from '../../../ui-kit/dialog';
import { SpeachStories } from '../../../shared/strories';

import CloseIcon from '../../../../images/Icon-Close-Filled.svg';
import Question from '../../../../images/story-speach.jpeg';
import SpeachIcon from '../../../../images/avatar-Speach.svg';
import { QuestionsBox } from '../../../shared/home-components/questions-box/question-box';

declare interface IStoriesDialog {
  showStory: boolean;
  stories?: any[];
  onClose: () => void;
  askQuestionVisibility: boolean;
}
export const StoriesDialog = ({
  showStory,
  stories,
  onClose,
  askQuestionVisibility,
}: IStoriesDialog) => {
  return (
    <Dialog open={showStory} animation={'scaleUp'} onClose={onClose}>
      {askQuestionVisibility ? (
        <div>
          <div
            onClick={onClose}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '50%',
              cursor: 'pointer',
              height: '36px',
              width: '36px',
              position: 'absolute',
              top: '1.2rem',
              right: '.2rem',
            }}
          >
            <img src={CloseIcon} alt={'*'} />
          </div>
          <div
            style={{
              display: 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'center',
              backgroundColor: '#ffffff',
              height: '640px',
              width: '360px',
              boxSizing: 'border-box',
              backgroundImage: `url('${Question}')`,
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                paddingLeft: '12px',
                marginTop: '1.5rem',
                width: '100%',
              }}
            >
              <img
                src={SpeachIcon}
                alt={'*'}
                style={{ width: '36px', height: '36px' }}
              />
              <label
                style={{
                  margin: '0 0.6rem',
                  fontWeight: 'bolder',
                  color: ' #343434',
                }}
              >
                Speach
              </label>
            </div>
            <QuestionsBox onSentQuestion={onClose} />
          </div>
        </div>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SpeachStories arrayOfStories={stories || []} onClick={onClose} />
        </div>
      )}
    </Dialog>
  );
};
