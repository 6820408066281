import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { ROUTES } from '../../helpers/consts/routes.constants';

declare interface IProtectedRoute {
  component: any;
  path: ROUTES;
  redirectPath: ROUTES;
  withAuth?: boolean;
}
export const ProtectedRoute = ({
  component: Component,
  withAuth = false,
  path,
  redirectPath,
  ...rest
}: IProtectedRoute) => (
  <Route
    {...rest}
    path={path}
    render={(props) =>
      withAuth ? <Component {...props} /> : <Redirect to={redirectPath} />
    }
  />
);
