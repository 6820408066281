import { createContext, useContext } from 'react';
import { IUserProps } from '../lib/interfaces';
import { initialUser } from '../consts/initial-user-state';
declare interface UserAthenticationProps {
  logIn: (token: string, user: IUserProps) => void;
  logOut: () => void;
  isAuthenticated: boolean;
  user: IUserProps;
  updateUser: (user: IUserProps) => void;
}

export const UserAthentication = createContext<UserAthenticationProps>({
  logIn: (token, user) => undefined,
  logOut: () => undefined,
  isAuthenticated: false,
  user: { ...initialUser },
  updateUser: (user) => undefined,
});
export const useUserAthentication = () => useContext(UserAthentication);
