import React, { useState, useRef, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import DatePicker, { registerLocale } from 'react-datepicker';
import el from 'date-fns/locale/fr'; // the locale you want
import moment from 'moment';
import AlertCircleIcon from 'mdi-react/AlertCircleOutlineIcon';

import { TextInput } from '../../../ui-kit/text-input';
import { Checkbox } from '../../../ui-kit/checkbox';

import Peach from '../../../../images/peach-sign-up.svg';
import { signUp } from '../../../../api/index';
import {
  emailValidation,
  passwordValidation,
} from '../../../../helpers/utils/inputs-validation';
import { AuthWrapper } from '../auth-wrapper';
import SignUpFooter from '../../../../images/sign-up-footer.svg';
import { Button } from '../../../ui-kit/button';
import { useOnClickOutside } from '../../../../helpers/costum-hooks/use-click-outside';

import styles from './sign-up.scss';
declare interface IErrors {
  firstName: boolean;
  lastName: boolean;
  dateOfBirth: boolean;
  code: boolean;
  email: boolean;
  password: boolean;
  confirmPassword: boolean;
  [key: string]: boolean;
}
registerLocale('el', el); // register it with the name you want
export const SignUp = (): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [formData, setFormData] = useState<{ [key: string]: any }>({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    code: '',
    email: '',
    password: '',
    confirmPassword: '',
    confirmCGU: false,
  });
  const [errors, setErrors] = useState<IErrors>({
    firstName: false,
    lastName: false,
    dateOfBirth: false,
    code: false,
    email: false,
    password: false,
    confirmPassword: false,
    confirmCGU: false,
  });
  const [isLoading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const myRef = useRef(null);
  useOnClickOutside(myRef, () => setErrorMessage(false));
  const handleChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName?: string,
  ) => {
    const { name, value } = e.target;
    inputName
      ? setFormData({ ...formData, [inputName]: value })
      : setFormData({ ...formData, [name]: value });
  };
  const isValidateForm =
    !Object.keys(errors).some((errorsKeys) => errors[errorsKeys]) &&
    formData.confirmCGU;
  const checkInputValidity = (name: string, value: any) => {
    if (name === 'email') {
      setErrors({
        ...errors,
        [name]: !emailValidation(value),
      });
    } else if (name === 'password') {
      setErrors({
        ...errors,
        [name]: !passwordValidation(value),
      });
    } else if (name === 'confirmPassword') {
      setErrors({
        ...errors,
        [name]: value !== formData.password,
      });
    } else if (name === 'dateOfBirth') {
      console.log('here');

      setErrors({
        ...errors,
        [name]: !isValidDate(formData.dateOfBirth),
      });
    } else {
      setErrors({
        ...errors,
        [name]: !value,
      });
    }
  };
  const handleSignUp = () => {
    //Fix me please follow reset password error handler
    const {
      firstName,
      lastName,
      dateOfBirth,
      code: establishmentCode,
      email,
      password,
      confirmPassword,
      confirmCGU,
    } = formData;
    const formattedDate = moment(dateOfBirth).format('YYYY-MM-DD');
    if (
      firstName &&
      lastName &&
      dateOfBirth &&
      formData.code &&
      email &&
      password &&
      confirmPassword &&
      confirmCGU
    ) {
      if (isValidateForm) {
        setLoading(true);
        signUp({
          firstName,
          lastName,
          dateOfBirth: formattedDate,
          establishmentCode,
          email,
          password,
          role: 'student',
        })
          .then((res: any) => {
            setLoading(false);
            enqueueSnackbar(
              'votre inscription a été bien enregistré,vous allez recevoir un mail de validation',
              {
                variant: 'success',
                autoHideDuration: 2000,
              },
            );
            history.push('/sign-in');
          })
          .catch((error) => {
            setLoading(false);
            enqueueSnackbar(
              error.message || 'un erreur se produite, verifier vos données',
              {
                variant: 'error',
                autoHideDuration: 2000,
              },
            );
          });
      }
    } else {
      const error = { ...errors };
      Object.keys(formData).map((errorKey) => {
        if (!firstName) {
          error.firstName = true;
        }
        if (!lastName) {
          error.lastName = true;
        }
        if (!dateOfBirth) {
          error.dateOfBirth = true;
        }
        if (!formData.code) {
          error.code = true;
        }
        if (!email) {
          error.email = true;
        }
        if (!password) {
          error.password = true;
        }
        if (!confirmPassword) {
          error.confirmPassword = true;
        }
        if (!confirmCGU) {
          error.confirmCGU = true;
        }
      });
      setErrors({ ...error });
    }
  };
  const handleBlurInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    inputName?: string,
  ) => {
    const { name, value } = e.target;
    checkInputValidity(inputName ? inputName : name, value);
  };
  function isValidDate(value: any) {
    const dateWrapper = new Date(value);
    return !isNaN(dateWrapper.getDate());
  }
  useEffect(() => {
    document
      .getElementsByTagName('input')[2]
      .setAttribute('readonly', 'readonly');
  }, []);

  return (
    <AuthWrapper imgUrl={SignUpFooter}>
      <form className={styles.signUpFormContainer}>
        <div className={styles.signUpTitleContainer}>
          <label className={styles.title}>Hello Speach</label>
          <img src={Peach} alt={'peach'} />
        </div>
        <label className={styles.pageTitle}>Création de ton compte</label>
        <TextInput
          value={formData.firstName}
          placeholder={'Ton prénom*'}
          type={'text'}
          name={'firstName'}
          error={errors.firstName}
          errorText={'entrez votre prénom'}
          onChange={handleChangeInput}
          onBlur={handleBlurInput}
        />
        <TextInput
          value={formData.lastName}
          placeholder={'Ton nom*'}
          type={'text'}
          name={'lastName'}
          error={errors.lastName}
          errorText={'entrez votre nom'}
          onChange={handleChangeInput}
          onBlur={handleBlurInput}
        />
        <div
          className={`${styles.datePickerContainer} ${
            errors.dateOfBirth && styles.datePickerError
          }`}
        >
          <DatePicker
            locale={'el'}
            onBlur={(e) => {
              handleBlurInput(e, 'dateOfBirth');
            }}
            //   if (typeof e.target.value !== 'object')
            //     setErrors({ ...errors, dateOfBirth: true });
            // }}
            dateFormat="dd-MM-yyyy"
            selected={formData.dateOfBirth}
            placeholderText="Ta date de naissance *"
            peekNextMonth={true}
            showMonthDropdown={true}
            showYearDropdown={true}
            dropdownMode="select"
            scrollableYearDropdown={true}
            maxDate={new Date()}
            minDate={new Date(2000, 1, 1)}
            onChange={(item: Date) => {
              setErrors({ ...errors, dateOfBirth: false });
              setFormData({
                ...formData,
                dateOfBirth: item,
              });
            }}
          />
          {errors.dateOfBirth && (
            <div className={styles.errorContainer} ref={myRef}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setErrorMessage(!errorMessage);
                }}
              >
                <AlertCircleIcon
                  id="eyeIcon"
                  style={{
                    cursor: 'pointer',
                    color: 'red',
                  }}
                />
              </div>
              {errorMessage && <span>inserez une date valide</span>}
            </div>
          )}
        </div>
        <TextInput
          value={formData.code}
          placeholder={'Saisi le code de ton établissement *'}
          error={errors.code}
          errorText={'entrez le code de ton établissement'}
          onChange={(e) => handleChangeInput(e, 'code')}
          onBlur={(e) => handleBlurInput(e, 'code')}
        />
        <TextInput
          value={formData.email}
          placeholder={'Ton email*'}
          type={'text'}
          name={'email'}
          error={errors.email}
          errorText={formData.email ? 'email non valide' : 'saisir votre email'}
          onChange={handleChangeInput}
          onBlur={handleBlurInput}
        />
        <TextInput
          value={formData.password}
          placeholder={'Choisi un mot de passe*'}
          type={'password'}
          name={'password'}
          error={errors.password}
          errorText={
            formData.password
              ? 'mot de passe non valide (8 caractères min, avec MAJ et minuscules, un chiffre, un caractère spécial)'
              : 'saisir votre mot de passe'
          }
          onChange={handleChangeInput}
          onBlur={handleBlurInput}
        />
        <TextInput
          value={formData.confirmPassword}
          placeholder={'Re-saisi ce mot de passe*'}
          type={'password'}
          name={'confirmPassword'}
          error={errors.confirmPassword}
          errorText={
            formData.confirmPassword
              ? 'assurez-vous que vos mots de passe correspondent'
              : 'confirmez votre mot de passe'
          }
          onChange={handleChangeInput}
          onBlur={handleBlurInput}
        />
        <div className={styles.returnLinkContainer}>
          <Checkbox
            warning
            id={'checked_1'}
            onChange={() => {
              setFormData({ ...formData, confirmCGU: !formData.confirmCGU });
              if (!formData.confirmCGU)
                setErrors({ ...errors, confirmCGU: formData.confirmCGU });
            }}
            checked={formData.confirmCGU}
          />
          <label
            style={{
              position: 'relative',
              color: errors.confirmCGU ? 'red' : '#ffffff',
              textAlign: 'center',
              fontSize: '0.8rem',
              padding:'0 8px'
            }}
          >
            En cochant cette case, j'accepte les{' '}
            <a href="/CGU.pdf" target="_blank">
              <span>
                <u style={{ color: errors.confirmCGU ? 'red' : '#ffee87' }}>
                  Conditions Générales d'Utilisation et la Politique de
                  confidentialité
                </u>
              </span>
            </a>{' '}
            , de Speach.*
          </label>
        </div>
        <Button
          className={styles.editButton}
          value={'CREER MON COMPTE'}
          light={true}
          onClick={handleSignUp}
          loading={isLoading}
        />
        <div className={styles.returnLinkContainer}>
          <label
            className={`${styles.returnLink}`}
            onClick={() => {
              history.push('/sign-in');
            }}
          >
            Retour à la page login
          </label>
        </div>
      </form>
    </AuthWrapper>
  );
};
