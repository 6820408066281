import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import { NavBar } from '../../shared/nav-bar';
import { StriesMenuCard } from '../../shared/home-components/stories-menu-card';
import { DiscussionCard } from '../../shared/home-components/discussion-card';
import { InfiniteLoader } from '../../ui-kit/infinite-loader';
import { Advertisements } from '../../shared/home-components/advertisements';
import { QuestionsBoxContainer } from '../../shared/home-components/questions-box/question-box-container';
import { useSpeachPostsContext } from '../../../helpers/context/speach-posts';
import { IPostItem } from '../../../helpers/lib/interfaces';
import { Spinner } from '../../ui-kit/spinner';
import { getStories } from '../../../api';
import { StoriesDialog } from '../../shared/dialogs/stories-dialog';

import styles from './home.scss';

export const Home = (): React.ReactElement => {
  const [showStory, setShowStory] = useState(false);
  const [stories, setStories] = useState<string[]>([]);
  const [askQuestionVisibility, setAskQuestionVisibility] = useState(false);
  const [storiesApp, setStoriesApp] = useState<any>({
    permanent: {
      intro: [],
      social: [],
      psycho: [],
      bio: [],
    },
    temporary: [],
  });

  const {
    isFirstLoad,
    loadPosts,
    loadMorePosts,
    posts,
    currentPage,
    totalPages,
  } = useSpeachPostsContext();

  const handleShowTemporaryStories = () => {
    if (storiesApp.temporary.length) {
      setShowStory(true);
      setStories([...storiesApp.temporary]);
    }
  };
  const handleShowPresentationStories = () => {
    if (storiesApp.permanent.intro.length) {
      setStories([...storiesApp.permanent.intro]);
      setShowStory(true);
    }
  };

  const handleShowBiologicStories = () => {
    if (storiesApp.permanent.bio.length) {
      setStories([...storiesApp.permanent.bio]);
      setShowStory(true);
    }
  };

  const handleShowPsycoStories = () => {
    if (storiesApp.permanent.psycho.length) {
      setStories([...storiesApp.permanent.psycho]);
      setShowStory(true);
    }
  };

  const handleShowSocialStories = () => {
    if (storiesApp.permanent.social.length) {
      setStories([...storiesApp.permanent.social]);
      setShowStory(true);
    }
  };

  const handleShowQuestionStories = () => {
    setAskQuestionVisibility(true);
    setShowStory(true);
  };

  const displayItems = () => {
    return posts.map((post: IPostItem, postIndex: number) => (
      <DiscussionCard key={postIndex} post={post} />
    ));
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    loadPosts();
    getStories().then((res: any) => {
      if (res.permanent) {
        setStoriesApp({
          ...storiesApp,
          ...res,
        });
      }
    });
  }, [showStory]);
  return (
    <>
      {showStory && (
        <StoriesDialog
          showStory={showStory}
          stories={stories}
          onClose={() => {
            setShowStory(false);
            setAskQuestionVisibility(false);
          }}
          askQuestionVisibility={askQuestionVisibility}
        />
      )}
      <div className={styles.homeContainer}>
        <NavBar />
        <div className={styles.content}>
          <div className={`${styles.sideSection} ${styles.leftSection}`}>
            <Advertisements />
          </div>
          <div className={styles.meduimSection}>
            <StriesMenuCard
              showTemporaryStories={handleShowTemporaryStories}
              showPresentationStories={handleShowPresentationStories}
              showBiologicStories={handleShowBiologicStories}
              showPsycoStories={handleShowPsycoStories}
              showSocialStories={handleShowSocialStories}
              showQuestionStories={handleShowQuestionStories}
              temporaryStory={storiesApp.temporary}
            />
            {!isFirstLoad ? (
              <>
                <InfiniteScroll
                  pageStart={currentPage}
                  loadMore={loadMorePosts}
                  hasMore={currentPage <= totalPages}
                  loader={
                    <div className={styles.InfiniteScrollLoader} key={0}>
                      <InfiniteLoader />
                    </div>
                  }
                >
                  {displayItems()} 
                </InfiniteScroll>
                {currentPage > totalPages && (
                  <div className={styles.meduimFooter}>Fin du fil 🎉</div>
                )}
              </>
            ) : (
              <Spinner className={styles.globalSpinner} />
            )}
          </div>
          <div className={`${styles.sideSection} ${styles.rightSection}`}>
            <QuestionsBoxContainer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
