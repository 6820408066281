import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import styles from './switch.scss';

export interface IntentProps {
  primary?: boolean;
  secondary?: boolean;
  success?: boolean;
  warning?: boolean;
  error?: boolean;
  info?: boolean;
}

export interface SwitchProps extends IntentProps {
  disabled?: boolean;
  active?: boolean;
  onChange?: (isChecked: boolean) => void;
  checked?: boolean;
  className?: string;
  id?: string;
  children?: React.ReactNode;
}
export const Switch = ({
  disabled = false,
  active = false,
  checked = false,
  primary = false,
  secondary = false,
  success = false,
  warning = false,
  error = false,
  info = false,
  id = '',
  className = '',
  onChange,
  children,
  ...rest
}: SwitchProps): React.ReactElement<SwitchProps> => {
  const [isChecked, setChecked] = useState(checked);
  useEffect(() => {
    setChecked(checked);
  }, [checked]);
  const toggleSwitch = () => {
    setChecked(!isChecked);
    if (onChange) onChange(!isChecked);
  };
  const props = {
    className: classnames(
      styles.switchButton,
      {
        [styles.switchButtonChecked]: isChecked,
      },
      className,
      {
        [styles.switchButtonPrimary]: primary,
        [styles.switchButtonSecondary]: secondary,
        [styles.switchButtonSuccess]: success,
        [styles.switchButtonWarning]: warning,
        [styles.switchButtonError]: error,
        [styles.switchButtonInfo]: info,
      },
      {
        [styles.switchButtonDisabled]: disabled,
      },
    ),
  };

  return (
    <React.Fragment>
      <div {...(props as React.HTMLProps<HTMLDivElement>)}>
        {children && (
          <label className={styles.switchButtonLabel}>{children}</label>
        )}
        <div className={styles.switchButtonInternal}>
          <div className={styles.switchButtonTrack} />
          <div className={styles.switchButtonThumbUnderlay}>
            <div className={styles.switchButtonThumb}>
              <input
                id={id}
                type={'checkbox'}
                disabled={disabled}
                checked={isChecked}
                onChange={toggleSwitch}
              />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
