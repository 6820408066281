import React from 'react';
import { useHistory } from 'react-router-dom';

import PeachStoriesmenu from '../../../../images/avatar-Speach.svg';
import BiologicStory from '../../../../images/biologic-story.jpg';
import PsycoStory from '../../../../images/psyco-story.jpg';
import SocialStory from '../../../../images/social-story.jpg';
import SponsorStory from "../../../../images/sponsor-story.png";
import IntroStory from "../../../../images/intro-story.jpg"
import MessageIcon from '../../../../images/icon-msg.svg';
import ConversationCheckedIcon from '../../../../images/conversation-completed.svg';
import ExternalLink from "../../../../images/external-link.svg"
import { IPostItem } from '../../../../helpers/lib/interfaces';
import { getDateDiffByNow } from '../../../../helpers/utils/get-date-diff-by-now';

import styles from './discussion-card.scss';

declare interface IDiscussionCard {
  post: IPostItem;
}

export const DiscussionCard = ({
  post,
}: IDiscussionCard): React.ReactElement<IDiscussionCard> => { 
  const history = useHistory();
  const { type, dateOfPublish, content, mediaName, externalLink } = post;
  //Fix me to change it by object of data instead of switch case
  const renderLogoContainerByType = (type: string) => {
    let url = '*';
    let postType = '';
    switch (type) {
      case 'social':
        url = SocialStory;
        postType = 'Social';
        break;
      case 'psycho':
        url = PsycoStory;
        postType = 'Psyco-affectif';
        break;
      case 'bio':
        url = BiologicStory;
        postType = 'Biologique';
        break;
      case 'sponsor':
        url = SponsorStory;
        postType = 'Sponsorisé';
        break;
      default:
        url = IntroStory;
        postType = 'Présentation';
        break;
    }
    return (
      <div className={styles.logoContainer}>
        <label>{postType}</label>
        <img src={url} alt="*" />
      </div>
    );
  };

  return (
    <div className={styles.discussionCard}>
      <div className={styles.headerCard}>
        <img src={PeachStoriesmenu} />
        <div className={styles.rightHeaderCard}>
          <div className={styles.topSection}>
            <div className={styles.subjectTitleContainer}>
              <label className={styles.subjectTitle}>Speach</label>
              <label className={styles.time}>
                {getDateDiffByNow(dateOfPublish)}
              </label>
            </div>
            {renderLogoContainerByType(type)}
          </div>
          <div className={styles.topicSubject}>{content || 'no content'}</div>
        </div>
      </div>
      {mediaName && (
        <img className={styles.media} src={mediaName} alt='*' />
      )}
      <div
        className={styles.footerDiscussionCard}
        onClick={() => post.type === 'sponsor' ? window.open(externalLink, '_blank')?.focus() : history.push(`/discussion/${post.id}`)}
      >
        <div>
          {post.type !== 'sponsor' &&
          <img
            src={
              post.conversations && post.conversations.length
                ? ConversationCheckedIcon
                : MessageIcon
            }
            alt='*'
          />}
          {post.type === 'sponsor' ? 'Voir' : 'Discuter'}
          {post.type === 'sponsor' &&
          <img
            src={ExternalLink}
            alt='*'
          />}
        </div>
      </div>
    </div>
  );
};
