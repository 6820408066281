import React from 'react';

import styles from './infinite-loader.scss';

export const InfiniteLoader = (): React.ReactElement => {
  return (
    <div id={'infiniteLoaderG'}>
      <div id={'blockG1'} className={styles.infiniteLoaderBlockG} />
      <div id={'blockG2'} className={styles.infiniteLoaderBlockG} />
      <div id={'blockG3'} className={styles.infiniteLoaderBlockG} />
    </div>
  );
};
