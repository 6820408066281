import React from 'react';
import classnames from 'classnames';

import {
  SizeProps,
  IntentProps,
  VariantProps,
} from '../../../helpers/lib/interfaces';
import { Spinner } from '../spinner';

import styles from './button.scss';

declare interface IButton extends SizeProps, IntentProps, VariantProps {
  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  className?: string;
  value: string;
  loading?: boolean;
}
export const Button = ({
  onClick = () => {
    return null;
  },
  value = '',
  className = '',
  loading = false,
  // Visual states.
  disabled = false,

  // Sizes.
  small = false,
  big = false,

  //Intent
  warning = true,
  light = false,
  secondary = false,

  //Variant
  outlined = false,
  ...rest
}: IButton): React.ReactElement<IButton> => {
  const props = {
    disabled,
    className: classnames(
      styles.buttonInput,
      className,
      {
        [styles.light]: !outlined ? light : false,
        [styles.warning]: warning,
        [styles.secondary]: secondary,
      },
      {
        //[styles.avatarSmall]: small,
        //[styles.avatarBig]: big,
      },
      {
        [styles.avatarDisabled]: disabled,
      },
      {
        [styles.outlined]: outlined,
      },
    ),
    ...rest,
  };
  return (
    <button
      {...props}
      type="button"
      onClick={(evt) => !loading && onClick(evt)}
    >
      {loading ? <Spinner /> : value}
    </button>
  );
};
