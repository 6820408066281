import React from 'react';
import classnames from 'classnames';
import { IntentProps } from '../../../helpers/lib/interfaces';
import styles from './spinner.scss';

export interface SpinnerProps extends IntentProps {
  className?: string;
}

export const Spinner = ({
  primary = false,
  secondary = false,
  success = false,
  warning = false,
  error = false,
  info = false,
  className = '',
  ...rest
}: SpinnerProps): React.ReactElement<SpinnerProps> => {
  const props = {
    className: classnames(styles.spinner, className, {
      [styles.primary]: primary,
      [styles.secondary]: secondary,
      [styles.success]: success,
      [styles.warning]: warning,
      [styles.error]: error,
      [styles.info]: info,
    }),
    ...rest,
  };

  return <progress {...props} />;
};
