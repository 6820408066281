import React from 'react';

import styles from './discussions-components.scss';

declare interface IUserMessage {
  text: string;
}
export const UserMessage = ({
  text,
}: IUserMessage): React.ReactElement<IUserMessage> => {
  return (
    <div className={styles.userMessageContainer}>
      <p className={styles.userMessageText}>{text}</p>
    </div>
  );
};
