import React from 'react';

import styles from './discussions-components.scss';

declare interface IUserMessage {
  responses: any[];
  onClick: (messg: string) => void;
}
export const ResponsesSelector = ({
  responses,
  onClick,
}: IUserMessage): React.ReactElement<IUserMessage> => {
  return (
    <div className={styles.responsesSelectorContainer}>
      {responses.map((response: any) => (
        <p
          key={response.id}
          className={styles.responsesSelectorText}
          onClick={() => onClick(response)}
        >
          {response.content}
        </p>
      ))}
    </div>
  );
};
