import { createContext, useContext } from 'react';
import { IPostItem } from '../lib/interfaces';

declare interface SpeachContextProps {
  posts: IPostItem[];
  totalPages: number;
  currentPage: number;
  loadPosts: () => void;
  loadMorePosts: () => void;
  updatePostAsCompleted: (postId: number) => void;
  isFirstLoad: boolean;
}
export const SpeachPostsContext = createContext<SpeachContextProps>({
  posts: [],
  totalPages: 1,
  currentPage: 1,
  loadPosts: () => undefined,
  loadMorePosts: () => undefined,
  updatePostAsCompleted: (postID) => undefined,
  isFirstLoad: false,
});
export const useSpeachPostsContext = () => useContext(SpeachPostsContext);
