import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import LoginBackground from '../../../../images/login-bg-img.svg';
import { TextInput } from '../../../ui-kit/text-input';
import Peach from '../../../../images/peach.svg';
import { recoverPassword } from '../../../../api/index';
import { emailValidation } from '../../../../helpers/utils/inputs-validation';
import { AuthWrapper } from '../auth-wrapper';
import { Button } from '../../../ui-kit/button';

import styles from './forgot-password.scss';

const PasswordRecovery = (): React.ReactElement => {
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const sendMail = () => {
    if (!error) {
      setLoading(true);
      recoverPassword({ email })
        .then((res: any) => {
          setLoading(false);
          enqueueSnackbar('envoyé avec succès, verifier votre boite mail', {
            variant: 'success',
            autoHideDuration: 2000,
          });
          history.push('./sign-in');
        })
        .catch((error: any) => {
          setLoading(false);
          enqueueSnackbar(
            error.message ||
              'un erreur se produite, verifier votre adresse mail',
            {
              variant: 'error',
              autoHideDuration: 2000,
            },
          );
        });
    }
  };
  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEmail(value);
  };
  const handleBlurInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setError(!emailValidation(value));
  };

  return (
    <AuthWrapper>
      <form className={styles.signInFormContainer}>
        <div className={styles.titleContainer}>
          <label className={styles.title}>Hello Speach</label>
          <img src={Peach} alt={'peach'} />
        </div>
        <TextInput
          value={email}
          placeholder={'entrez votre adresse email'}
          type={'text'}
          name={'email'}
          onChange={handleChangeInput}
          onBlur={handleBlurInput}
          error={error}
          errorText={!email ? 'entrez votre adresse email' : 'email invalide'}
        />
        <Button
          value={'ENVOYER UN CODE'}
          light={true}
          onClick={!email ? () => setError(true) : sendMail}
          loading={isLoading}
        />
        <div className={styles.returnLinkContainer}>
          <label
            className={`${styles.returnLink}`}
            onClick={() => {
              history.push('/sign-in');
            }}
          >
            Retour à la page login
          </label>
        </div>
      </form>
      <img src={LoginBackground} alt={'*'} />
    </AuthWrapper>
  );
};
export default PasswordRecovery;
